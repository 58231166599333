import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { InviteListSelectorModalComponent } from '../invite-list-selector-modal/invite-list-selector-modal.component';

@Component({
  selector: 'app-reactive-invite-list',
  templateUrl: './reactive-invite-list.component.html',
})
export class ReactiveInviteListComponent implements OnInit {
  @Input() showInvitedDealers: boolean = true;
  @Input() showQty: number = 4;
  @Input() isRequired: boolean = false;
  @Input() invitedDealersFormControl!: FormControl;
  @Input() isEditMode: boolean = false;
  touched: boolean = false;
  emptySelection: boolean = false;
  invitedSuppliers: string = '';
  @ViewChild(InviteListSelectorModalComponent, { static: false })
  dealersInviteListSelectorDialog!: InviteListSelectorModalComponent;

  constructor() {}

  ngOnInit() {
    this.invitedSuppliers = this.invitedDealersFormControl.value;
  }

  showSelectorModal() {
    this.dealersInviteListSelectorDialog.showDialog();
  }

  onSaveDealersInvited(invitedSuppliers: string[]) {
    this.invitedDealersFormControl.setValue(invitedSuppliers);
    this.invitedDealersFormControl.markAllAsTouched();
    this.invitedDealersFormControl.markAsDirty();
    this.setSelectedDealersInvited();
  }

  setSelectedDealersInvited() {
    this.invitedSuppliers = this.invitedDealersFormControl.value;
    this.touched = this.invitedDealersFormControl.touched;
    this.emptySelection = this.invitedSuppliers.length === 0;
  }
}
