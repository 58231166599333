import { GoogleAnalyticsService } from './../../../services/common/google-analytics.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { HelpService } from 'src/app/services/help/help.service';
import { IHelpQuestion } from '../../../models/help';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastAlertComponent } from 'src/app/ui/toast-alert/toast-alert.component';
import { CatalogService } from '../../../services/users/catalog.service';
import { CacheService } from '../../../services/helper/cache.service';
import { DragulaService } from 'ng2-dragula';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { CategoriesCode } from '../../../models/help';
import { TimeoutConfig } from 'src/app/fixtures/app-settings/constants';

@Component({
  selector: 'app-help-questions',
  templateUrl: './help-questions.component.html',
})
export class HelpQuestionsComponent implements OnInit {
  @ViewChild('questionModal', { static: false }) questionModal?: ModalDirective;
  @Input() token: any;
  @Input() canEdit: boolean = false;
  @Output() selectVideo = new EventEmitter();

  categoriesList: any = [];

  subscription: Subscription = new Subscription();
  addEditQuestion: FormGroup;
  selectedCategory: string = '';
  selectedQuestionId: string = '';
  questions: IHelpQuestion[] = [];
  questionsList: IHelpQuestion[] = [];
  loading = false;
  showError = false;

  // Notification
  notificationDialogMessage: string = '';
  showDiscardAlert: boolean = false;
  showDeleteAlert: boolean = false;
  showCreateAlert: boolean = false;
  successAlert: boolean = false;
  genericError: boolean = false;

  // Toast Components
  @ViewChild('deleteToast') deleteToastAlert!: ToastAlertComponent;

  // Loading Gifs
  categoryLoading: boolean = false;
  buttonLoading: boolean = false;

  // Modals
  modalOnEdit: boolean = false;

  // Video MOCK
  helpVideo: any;

  // Angular Editor Config
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    minHeight: '15rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    rawPaste: true,
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['insertVideo', 'toggleEditorMode', 'fontName']],
  };

  constructor(
    private helpService: HelpService,
    private formBuilder: FormBuilder,
    private googleAnalyticsService: GoogleAnalyticsService,
    private cacheService: CacheService,
    private catalogService: CatalogService,
    private _dragulaService: DragulaService
  ) {
    this.addEditQuestion = this.formBuilder.group({
      question: new FormControl('', [Validators.required]),
      answer: new FormControl('', [Validators.required]),
      videoUrl: new FormControl(''),
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this._dragulaService.destroy('Questions');
  }

  ngOnInit() {
    this.getCategories();
    this.dragulaInit();
  }

  getCategories() {
    this.categoryLoading = true;
    this.subscription.add(
      this.helpService.getCategories(this.token).subscribe({
        next: (response: any) => {
          this.categoriesList = response;
          this.categoriesList = this.hideCategories(response);
        },
        complete: () => {
          this.categoryLoading = false;
        },
        error: (error) => {
          this.categoryLoading = false;
          console.error(error);
        },
      })
    );
  }

  // TEMPORARY FUNCTION - hide categories
  hideCategories(categories: any[]) {
    let categoriesToHide = [
      CategoriesCode.PRODUCTS,
      CategoriesCode.JACKSON_ACADEMY,
      CategoriesCode.PRESONUS_ACADEMY,
    ];
    return categories.filter(
      (category) => !categoriesToHide.includes(category.code)
    );
  }

  selectCategory(category: string) {
    const collection = document.getElementsByClassName('answers-section');
    let categoryElements = Array.from(collection);
    categoryElements.map((element: any) => {
      if (element.classList.contains('show')) {
        const cCode = element.id.split('-').slice(1).join('-');
        this.toggleCategory(false, cCode);
      } else if (element.id === `category-${category}`) {
        this.getQuestions(category);
      }
    });
  }

  selectAnswer(question: IHelpQuestion) {
    if (question.url) {
      this.helpVideo = this.helpService.getVideoFromUrl(question);
    }
    const collection = document.getElementsByClassName('answer-container');
    let categoryElements = Array.from(collection);
    categoryElements.map((element: any) => {
      if (`answer-${question.category}-${question.id}` == element.id) {
        if (element.classList.contains('show')) {
          this.toggleAnswer(false, element.id);
        } else {
          this.toggleAnswer(true, element.id);
          this.googleAnalyticsService.trackEvent(
            'faq',
            'Click Question',
            question.title
          );
        }
      } else {
        this.toggleAnswer(false, element.id);
      }
    });
  }

  onSelectVideo(videoSelected: any) {
    this.selectVideo.emit(videoSelected);
  }

  getQuestions(category: string) {
    this.subscription.add(
      this.helpService.getHelpQuestions(this.token, category).subscribe({
        next: (response: IHelpQuestion[]) => {
          this.questionsList = response;
        },
        complete: () => {
          this.toggleCategory(true, category);
        },
        error: (error) => {
          console.error(error);
        },
      })
    );
  }

  toggleAnswer(selected: boolean, id: string) {
    if (selected) {
      document.getElementById(id)?.classList.add('show');
    } else {
      document.getElementById(id)?.classList.remove('show');
    }
  }

  toggleCategory(selected: boolean, category: any) {
    let categoryShow = document.getElementById(`category-${category}`);
    let chevronOpen = document.getElementById(`${category}-chevron`);

    if (selected) {
      categoryShow?.classList.add('show');
      chevronOpen?.classList.add('open');
    } else {
      categoryShow?.classList.remove('show');
      chevronOpen?.classList.remove('open');
    }
  }

  onDiscardQuestion() {
    if (this.addEditQuestion.pristine) {
      this.questionModal?.hide();
    } else {
      this.notificationDialogMessage = 'Do you want to close without save?';
      this.showDiscardAlert = true;
    }
  }

  confirmDiscardQuestion(confirmation: boolean) {
    this.showDiscardAlert = false;
    if (confirmation) {
      this.questionModal?.hide();
    }
  }

  onNewQuestion(category: string) {
    this.modalOnEdit = false;
    this.addEditQuestion.reset();
    this.questionModal?.show();
    this.selectedCategory = category;
  }

  saveNewQuestion() {
    this.buttonLoading = true;
    this.showCreateAlert = false;
    this.genericError = false;
    this.subscription.add(
      this.helpService
        .createQuestion(
          this.addEditQuestion.value,
          this.selectedCategory,
          this.token
        )
        .subscribe({
          next: (saved) => {
            if (saved) {
              this.successAlert = true;
            }
          },
          complete: () => {
            this.buttonLoading = false;
            this.selectedCategory = '';
            this.addEditQuestion.reset();
            this.getCategories();
            setTimeout(() => {
              this.successAlert = false;
              this.questionModal?.hide();
            }, TimeoutConfig.ALERT_BANNER_SUCCESS);
          },
          error: (err) => {
            this.buttonLoading = false;
            this.genericError = true;
            setTimeout(() => {
              this.genericError = false;
            }, TimeoutConfig.ALERT_BANNER_ERROR);
            console.error(err);
          },
        })
    );
  }

  onEditQuestion(question: IHelpQuestion) {
    this.modalOnEdit = true;
    this.selectedCategory = question.category;
    this.selectedQuestionId = question.id;
    this.addEditQuestion.patchValue(
      {
        question: question.title,
        answer: question.description,
        videoUrl: question.url ? question.url : '',
      },
      { emitEvent: false }
    );
    this.addEditQuestion.markAsPristine();
    this.questionModal?.show();
  }

  saveUpdatedQuestion() {
    this.buttonLoading = true;
    this.showCreateAlert = false;
    this.genericError = false;
    this.subscription.add(
      this.helpService
        .updateQuestion(
          this.addEditQuestion.value,
          this.selectedCategory,
          this.selectedQuestionId,
          this.token
        )
        .subscribe({
          next: (saved) => {
            if (saved) {
              this.successAlert = true;
            }
          },
          complete: () => {
            this.buttonLoading = false;
            this.selectedCategory = '';
            this.selectedQuestionId = '';
            this.addEditQuestion.reset();
            this.getCategories();
            setTimeout(() => {
              this.successAlert = false;
              this.questionModal?.hide();
            }, TimeoutConfig.ALERT_BANNER_SUCCESS);
          },
          error: (err) => {
            this.buttonLoading = false;
            this.genericError = true;
            setTimeout(() => {
              this.genericError = false;
            }, TimeoutConfig.ALERT_BANNER_ERROR);
            console.error(err);
          },
        })
    );
  }

  onDeleteQuestion() {
    this.notificationDialogMessage = 'Do you want to delete this question?';
    this.showDeleteAlert = true;
  }

  confirmDeleteQuestion(confirmation: boolean) {
    this.showDeleteAlert = false;
    if (confirmation) {
      this.subscription.add(
        this.helpService
          .deleteQuestion(
            this.selectedCategory,
            this.selectedQuestionId,
            this.token
          )
          .subscribe({
            complete: () => {
              this.deleteToastAlert?.show();
              this.selectedCategory = '';
              this.selectedQuestionId = '';
              this.addEditQuestion.reset();
              this.getCategories();
              this.questionModal?.hide();
            },
          })
      );
    }
  }

  dragulaInit() {
    this._dragulaService.createGroup('Questions', {
      moves: (el, container, handle: any) => {
        return this.canEdit
          ? handle.classList.contains('question-sort-handle')
          : false;
      },
    });
  }

  dragDropEvent(event: any, category: string) {
    const questionsArray = this.questionsList.map((document) => document.id);
    this.subscription.add(
      this.catalogService
        .sortList(`faq-${category}`, questionsArray)
        .subscribe({
          complete: () => {
            this.cacheService.delete(`help-questions-${category}`);
          },
          error: (error) => {
            console.error(error);
          },
        })
    );
  }
}
