export const rolesCatalog = [
  {
    id: 1,
    role: 'FMIC Admin',
    code: 'FMIC-Admin',
    description: 'An administrator for the B2B Site',
    category: 'Fender',
  },
  {
    id: 2,
    role: 'Customer Service',
    code: 'FMIC-Customer-Service',
    description: 'A Customer Service',
    category: 'Fender',
  },
  {
    id: 3,
    role: 'Fender Internal',
    code: 'FMIC-Fender-Internal',
    description: 'A Fender Internal',
    category: 'Fender',
  },
  {
    id: 4,
    role: 'Supplier Admin',
    code: 'Supplier-Admin',
    description:
      'Supplier Admin has full access to all functions for an account',
    category: 'Supplier',
  },
  {
    id: 5,
    role: 'Supplier Manager',
    code: 'Supplier-Manager',
    description: 'Supplier Manager',
    category: 'Supplier',
  },
  {
    id: 6,
    role: 'Supplier Finance',
    code: 'Supplier-Finance',
    description: 'Supplier Finance',
    category: 'Supplier',
  },
  {
    id: 7,
    role: 'Supplier Factory',
    code: 'Supplier-Factory',
    description: 'Supplier Factory',
    category: 'Supplier',
  },
];
