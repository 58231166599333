<div #videoContainer id="video-thumbnail_container" data-cy="video-thumbnail-container"
    class="video-thumbnail_container--black">
    <div *ngIf="!useCircleProgressbar" 
        [class.static-video-thumbnail-small]="videoCont?.nativeElement?.offsetWidth &lt; 260" (click)="showVideo()">
        <img *ngIf="videoThumbnail" src="{{videoThumbnail}}" data-cy="video-thumbnail-image"
            class="img-responsive video-thumbnail-image" />

        <circle-progress-bar *ngIf="videoUrl" class="circle-progress-bar"
            [progressPercentage]="progressPercentage">
        </circle-progress-bar>
    </div>
</div>


<ng-template #videoPlayerModal>
    <show-player [eventId]="eventId" [videoTitle]="videoTitle" [videoId]="videoId" [videoUrl]="videoUrl" [startTime]="startTime"
        [trackActivity]="true" [eventCategory]="eventCategory" [pageSelected]="pageSelected" [pageTitle]="pageTitle" [videoSubtitles]="videoSubtitles"
        (activityChange)="onActivityChange($event)" (languageSelected)="onLanguageSelected($event)"
        (statusChanged)="onStatusChanged($event)" (onCloseVideo)="closeVideo()">
    </show-player>
    <ng-template>