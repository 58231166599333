<div class="container-fluid py-5">
  <div class="row gy-4">
    <div class="col-12 col-lg-8">
      <app-help-questions
        [token]="token"
        [canEdit]="canEdit"
        (selectVideo)="onSelectVideo($event)"
        *ngIf="token"
      ></app-help-questions>
    </div>
    <div class="col-12 col-lg-4">
      <app-help-videos
        [token]="token"
        [canEdit]="canEdit"
        (selectVideo)="onSelectVideo($event)"
        *ngIf="token"
      ></app-help-videos>
    </div>
  </div>
</div>

<!-- === VIDEO PLAYER - MODAL === -->
<div
  bsModal
  #videoPlayer="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
>
  <div class="modal-dialog modal-lg video-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="f-font f-bold modal-title pull-left">
          {{ selectedVideoTitle }}
        </h3>
        <button
          type="button"
          class="btn-close close pull-right"
          (click)="clearSelectedVideo()"
          aria-label="Close"
        >
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div
        class="modal-body d-flex justify-content-center contained-video-modal"
      >
        <app-video-player
          [videoCategory]="'videoCategory'"
          [videoTitle]="selectedVideoTitle"
          [url]="selectedVideoUrl"
          [autoplay]="true"
        ></app-video-player>
      </div>
    </div>
  </div>
</div>
