import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { filter, Observable, Subscription } from 'rxjs';
import { ROLES } from 'src/app/models/role';
import { SelectSupplierHelperService } from 'src/app/services/helper/select-supplier-helper.service';
import { IUser } from '../../models/user';
import { IdentityService } from '../../services/common/identity.service';
import { PurchaseOrdersRoutes } from 'src/app/models/purchase-orders';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrdersAccess implements CanActivate {
  subscription: Subscription = new Subscription();
  mUser!: IUser;
  urlSuppleriNumber: string | null = null;

  constructor(
    private selectSupplierHelperService: SelectSupplierHelperService,
    private identityService: IdentityService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const poRoute = state.url.split('/').pop();
    let urlSupplierNumber = route.queryParams['supplierNumber'];
    return new Observable<boolean>((obs) => {
      this.identityService
        .getCurrentMeUser()
        .pipe(filter<any>(Boolean))
        .subscribe((value) => {
          if (value) {
            this.mUser = value;
            if (
              (this.mUser.role === ROLES.SUPPIER_ADMIN ||
                this.mUser.role === ROLES.SUPPIER_MANAGER ||
                this.mUser.role === ROLES.SUPPIER_FINANCE) &&
              poRoute != PurchaseOrdersRoutes.SUMMARY
            ) {
              obs.next(true);
            } else if (
              this.mUser.role === ROLES.FMIC_ADMIN ||
              this.mUser.role === ROLES.FMIC_CUSTOMER_SERVICE
            ) {
              this.selectSupplierHelperService.getValue().subscribe((value) => {
                if (value || urlSupplierNumber) {
                  obs.next(true);
                  urlSupplierNumber = null;
                } else if (
                  poRoute === PurchaseOrdersRoutes.SUMMARY ||
                  PurchaseOrdersRoutes.DETAILS
                ) {
                  obs.next(true);
                } else {
                  this.router.navigate(['/dashboard']);
                  obs.next(false);
                }
              });
            } else {
              this.router.navigate(['/dashboard']);
              obs.next(false);
            }
          }
        });
    });
  }
}
