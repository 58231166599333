import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, filter, firstValueFrom, flatMap, switchMap } from 'rxjs';
import { AuthenticationService } from '../services/users/authentication.service';
import { UserService } from '../services/users/user.service';
import { WebsocketService } from '../services/lib/websocket.service';
import { IUser } from '../models/user';
import { SelectSupplierHelperService } from '../services/helper/select-supplier-helper.service';
import { ROLES } from '../models/role';
import { IdentityService } from '../services/common/identity.service';
@Component({
  selector: 'suppliers-app-root',
  templateUrl: './root.component.html',
})
export class RootComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  mUser!: IUser;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private webSocketService: WebsocketService,
    private identityService: IdentityService,
    private selectSupplierHelperService: SelectSupplierHelperService
  ) {}
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async ngOnInit() {
    const authUser = await this.authenticationService.getCurrentAuthUser();
    if (!authUser.signInUserSession) {
      return;
    }
    const token = authUser.signInUserSession.idToken.jwtToken;
    this.authenticationService.getUserInfo().then((userInfo) => {
      this.identityService.currentUserInfo$.next(userInfo);
    });

    this.userService.getMeUser().subscribe({
      next: (data) => {
        this.mUser = data;
        this.identityService.currentMeUser$.next(data);
      },
      complete: () => {
        this.webSocketService.init(token, this.mUser.email, this.mUser.role);
        this.authenticationService.setSupplierNumberFromUrl();
        this.storeSupplierNumberOnRole();
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
  onSubmit(event: any) {
    console.log(event);
  }

  setSupplieNumber(supplierNumber: string | undefined) {
    if (supplierNumber) {
      this.selectSupplierHelperService.setValue(supplierNumber);
    }
  }

  storeSupplierNumberOnRole() {
    if (
      this.mUser.role != ROLES.FMIC_ADMIN &&
      this.mUser.role != ROLES.FMIC_CUSTOMER_SERVICE &&
      this.mUser.role != ROLES.FMIC_INTERNAL
    ) {
      this.setSupplieNumber(this.mUser.supplierNumber);
    }
  }
}
