import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-shipment-question',
  templateUrl: './shipment-question.component.html',
})
export class ShipmentQuestionComponent implements OnInit {
  @Input() parentForm!: FormGroup;
  @Input() shipmentQuestionForm!: FormGroup;

  // NgModels Inputs
  lengthInput: string = '';
  widthInput: string = '';
  heightInput: string = '';
  dimensions: string[] = [];

  // Arrays
  shipmentTypeSelect: string[] = ['Sample', 'Container'];
  shipmentItemSelect: string[] = ['Guitar/Bass', 'Amp', 'Accesories', 'Other'];
  brandsIcludedSelect: string[] = [
    'Fender',
    'EVH',
    'Gretsch',
    'Jackson',
    'Charvel',
  ];

  constructor() {}
  ngOnInit(): void {}

  onBrandsCheckboxChange(event: any) {
    const selectedCountries = this.shipmentQuestionForm.controls[
      'brands'
    ] as FormArray;
    if (event.target.checked) {
      selectedCountries.push(new FormControl(event.target.value));
    } else {
      const index = selectedCountries.controls.findIndex(
        (x) => x.value === event.target.value
      );
      selectedCountries.removeAt(index);
    }
  }
}
