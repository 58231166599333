import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { IdentityService } from '../../../../services/common/identity.service';

@Component({
  selector: 'app-date-change-modal',
  templateUrl: './date-change-modal.component.html',
  styles: [],
})
export class DateChangeModalComponent implements OnInit {
  @Input() public originalShipDate!: string;

  @Output() conCloseModal = new EventEmitter();
  @Output() onSaveDateChangeRequest = new EventEmitter<string>();

  userDateFormat: string = '';
  requestedDateChange: string = '';
  minDate: Date = new Date();
  dateChangeControl: FormControl = new FormControl('');
  datePickerConfig: Partial<BsDatepickerConfig> = Object.assign(
    {},
    { containerClass: 'theme-red', showWeekNumbers: false }
  );

  constructor(private identityService: IdentityService) {}

  ngOnInit(): void {
    this.getUserDateFormat();
    console.log(this.dateChangeControl.value);
  }

  getUserDateFormat() {
    this.identityService.getCurrentMeUser().subscribe((user: any) => {
      this.userDateFormat = user.dateFormat || 'MM-dd-yyyy';
    });
  }

  onDateValueChange(value: Date): void {
    this.requestedDateChange = value.toISOString().split('T')[0];
  }

  closeDateChangeModal() {
    this.conCloseModal.emit();
  }
  saveDateChangeRequest() {
    this.onSaveDateChangeRequest.emit(this.requestedDateChange);
    this.closeDateChangeModal();
  }
}
