export interface IHelpQuestion {
  title: string;
  description?: string;
  category: string;
  url: string;
  creationDate: string;
  id: string;
  timestamp?: string;
  type?: string;
  expanded?: boolean;
  isQuestion?: boolean;
}

export interface IHelpCategory {
  code: string;
  description: string;
  active: boolean;
  icon?: string;
}

export enum CategoriesCode {
  ACCOUNT_MAINTENANCE = 'account-maintenance',
  PURCHASE_ORDERS = 'purchase-orders',
  DOCUMENTS = 'documents',
  TOOLS = 'tools',
  EVENTS = 'events',
  PRODUCTS = 'products',
  JACKSON_ACADEMY = 'jackson-academy',
  PRESONUS_ACADEMY = 'presonus-academy',
  TRAINING = 'training',
  OTHER = 'other',
}
