<div class="card">
  <div class="card-header dark-header-lg">
    <h3 class="f-font f-bold m-0">Help Categories</h3>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div
        class="py-4 d-flex flex-column justify-content-center align-items-center"
      >
        <h2 class="f-font f-bold m-0">How can we help you?</h2>
        <p class="f-font m-0">Frequently Asked Questions</p>
      </div>
    </li>
    <!-- Category Loading -->
    <ng-template [ngIf]="categoryLoading">
      <div class="d-flex justify-content-center py-5">
        <app-fender-tone-loading-div
          [widget]="true"
          [black]="true"
        ></app-fender-tone-loading-div>
      </div>
    </ng-template>

    <!-- Accordion Section -->
    <!-- Accordion Category Item -->
    <div *ngFor="let category of categoriesList">
      <li
        class="list-group-item category"
        type="button"
        (click)="selectCategory(category.code)"
      >
        <div class="d-flex justify-content-between align-items-center py-2">
          <div class="d-flex align-items-center">
            <i class="f-{{ category.icon }} f-icon f-md me-3 mb-2"></i>
            <h3 class="f-font m-0">
              {{ category.description }}
            </h3>
          </div>
          <i
            id="{{ category.code }}-chevron"
            class="fa-solid fa-chevron-down category-chevron"
          ></i>
        </div>
      </li>
      <!-- Accordion Category Answers Section -->
      <li
        id="category-{{ category.code }}"
        class="list-group-item px-4 answers-section"
      >
        <!-- Accordion Category Answers Item -->
        <div *ngIf="questionsList.length; else sectionWithoutQuestions">
          <div
            dragula="Questions"
            [(dragulaModel)]="questionsList"
            (dragulaModelChange)="dragDropEvent($event, category.code)"
          >
            <div
              *ngFor="let question of questionsList; let questionIndex = index"
            >
              <div
                class="d-flex justify-content-between align-items-center py-2"
              >
                <div class="d-flex align-items-center">
                  <i
                    *ngIf="canEdit"
                    class="fa-solid fa-bars grabbable-pointer question-sort-handle me-3"
                  ></i>
                  <h4
                    class="f-font f-bold m-0 py-2"
                    type="button"
                    (click)="selectAnswer(question)"
                  >
                    {{ questionIndex + 1 }} -
                    {{ question.title }}
                  </h4>
                </div>
                <button
                  [hidden]="!canEdit"
                  class="f-btn edit-question-btn f-xs f-black"
                  (click)="onEditQuestion(question)"
                >
                  <i class="fa-solid fa-pen fa-sm m-0"></i>
                </button>
              </div>
              <div
                id="answer-{{ category.code }}-{{ question.id }}"
                class="answer-container"
              >
                <div
                  class="f-font images-contained-modal"
                  [innerHTML]="
                    question.description
                      ? (question.description | safeHtml)
                      : ''
                  "
                ></div>
                <!-- IF QUESTION HAS VIDEO URL -->
                <div
                  *ngIf="question.url"
                  class="d-flex justify-content-center mb-3 py-2"
                >
                  <app-video-thumbnail
                    [video]="helpVideo"
                    [height]="'100%'"
                    [width]="'100%'"
                    [maxHeight]="'270px'"
                    [showTitles]="false"
                    (onSelectVideo)="onSelectVideo(helpVideo)"
                    [canEdit]="false"
                  ></app-video-thumbnail>
                </div>
              </div>
              <hr class="m-0 question-separator" />
            </div>
          </div>
        </div>
        <!-- IF NO QUESTOINS - Message -->
        <ng-template #sectionWithoutQuestions>
          <div
            class="pb-3 pt-5 d-flex justify-content-center empty-section-text"
          >
            <span class="f-font">No results found </span>
          </div>
        </ng-template>

        <!-- Button Section -->
        <div [hidden]="!canEdit" class="pt-3 pb-2">
          <button
            class="f-btn f-blue f-xs"
            (click)="onNewQuestion(category.code)"
          >
            <i class="fa-solid fa-plus me-1"></i>
            <span class="f-font">Add New</span>
          </button>
        </div>
      </li>
    </div>
  </ul>
</div>

<!-- === QUESTION - MODAL === -->
<div
  bsModal
  #questionModal="bs-modal"
  class="modal fade"
  role="dialog"
  [config]="{ backdrop: 'static' }"
  aria-labelledby="dialog-sizes-name1"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <!-- MODAL HEADER -->
      <div class="modal-header dark-header-lg">
        <h3 id="dialog-sizes-name1" class="modal-title pull-left f-font">
          {{ modalOnEdit ? "Edit Question" : "New Question" }}
        </h3>
        <div type="button" class="py-1 px-2" (click)="onDiscardQuestion()">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
      <!-- MODAL BODY -->
      <div class="modal-body">
        <form [formGroup]="addEditQuestion">
          <div class="mb-3">
            <label for="questionTitle" class="form-label f-font"
              >Question Title</label
            >
            <input
              id="questionTitle"
              type="text"
              class="form-control f-font"
              formControlName="question"
            />
          </div>
          <div class="mb-3">
            <label for="answerDescription" class="form-label f-font"
              >Answer Description</label
            >
            <angular-editor
              class="f-font"
              [config]="editorConfig"
              formControlName="answer"
            ></angular-editor>
          </div>
          <div class="mb-3">
            <label for="videoURL" class="form-label f-font">Video URL</label>
            <input
              id="videoURL"
              type="text"
              class="form-control f-font"
              formControlName="videoUrl"
              placeholder="https://"
            />
            <span class="f-font ms-1"
              ><small>Only video URLs from Cloudfront</small></span
            >
          </div>
        </form>
      </div>
      <!-- MODAL FOOTER -->
      <div class="modal-footer">
        <button
          type="button"
          class="f-btn f-xs f-red me-2"
          *ngIf="modalOnEdit"
          (click)="onDeleteQuestion()"
        >
          <i class="fa-solid fa-trash-can me-0"></i>
        </button>
        <button
          *ngIf="!modalOnEdit"
          type="button"
          class="f-btn f-xs f-blue"
          [disabled]="!addEditQuestion.valid || buttonLoading"
          (click)="saveNewQuestion()"
        >
          <i class="fa-solid fa-plus me-1"></i
          ><span class="f-font">Add Question</span
          ><span class="ms-2" *ngIf="buttonLoading">
            <app-fender-tone-loading-div
              [inline]="true"
            ></app-fender-tone-loading-div>
          </span>
        </button>
        <button
          *ngIf="modalOnEdit"
          type="button"
          class="f-btn f-xs f-blue"
          [disabled]="
            !addEditQuestion.valid || addEditQuestion.pristine || buttonLoading
          "
          (click)="saveUpdatedQuestion()"
        >
          <i class="fa-solid fa-floppy-disk me-2"></i
          ><span class="f-font">Save Question</span>
          <span class="ms-2" *ngIf="buttonLoading">
            <app-fender-tone-loading-div
              [inline]="true"
            ></app-fender-tone-loading-div>
          </span>
        </button>
        <div class="w-100">
          <!-- Success Alert - Question Created -->
          <div
            class="alert alert-success mt-3"
            role="alert"
            *ngIf="successAlert"
          >
            <h3 class="alert-heading f-font f-bold">
              <i class="fa-solid fa-check"></i>
              Question successfully created
            </h3>
            <p class="f-font mb-1">Your question has been added.</p>
          </div>
          <!-- GenericError Alert - Question Error -->
          <div
            class="alert alert-danger mt-3"
            role="alert"
            *ngIf="genericError"
          >
            <h3 class="alert-heading f-font f-bold">
              <i class="fa-solid fa-triangle-exclamation"></i>
              Unable to complete.
            </h3>
            <p class="f-font mb-1">We have ecounter an error.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ============= Notifications Alerts ============ -->
<!-- ALERT - DISCARD CHANGES -->
<app-notification-dialog
  [title]="'Alert'"
  [description]="notificationDialogMessage"
  *ngIf="showDiscardAlert"
  (confirmFunction)="confirmDiscardQuestion($event)"
></app-notification-dialog>

<!-- ALERT - DELETE QUESTION -->
<app-notification-dialog
  [title]="'Alert'"
  [description]="notificationDialogMessage"
  *ngIf="showDeleteAlert"
  (confirmFunction)="confirmDeleteQuestion($event)"
></app-notification-dialog>

<!-- ============= Toast Alerts ============ -->
<!-- Delete Toast -->
<app-toast-alert
  #deleteToast
  [selector]="'delete'"
  [title]="'Alert'"
  [description]="'Question successfully deleted'"
  [style]="'bg-success'"
></app-toast-alert>
