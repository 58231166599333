import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { IEventImage } from 'src/app/models/events';
import { HelperService } from 'src/app/services/helper/helper.service';
import { StorageManagerService } from 'src/app/services/managers/storage-manager.service';
import { ImageCropModalComponent } from '../image-crop-modal/image-crop-modal.component';

@Component({
  selector: 'app-reactive-image-selector',
  templateUrl: './reactive-image-selector.component.html',
})
export class ReactiveImageSelectorComponent implements OnInit, AfterViewInit {
  @Input() imageFormControl!: FormControl;
  @Input() required = false;
  @Input() imageUrl: string = '';
  @Input() withCropper = true;
  @Input() forceCrop = false;
  @Input() useImageUploadService = true;
  @Input() aspectRatio = 1.5;
  @Input() uuid = false;
  @Input() resolutionText: string = '';
  @Input() maxMBs = 1;
  @Input() canRemove = false;
  @Input() disabled = false;
  @Input() imageNamePrefix: string = '';
  @Input() allowImageUrl = false;
  @Input() horizontalLayout = false;
  @Output() uploadedFileName = new EventEmitter();

  isFile = false;
  isUrl = false;

  imageSourceType = '';
  imageUrlSource: string = '';
  errorImageUrlRequired = false;
  errorImageUrlInvalid = false;

  maxSize = this.maxMBs * 1024 * 1024;
  maxSizeError: boolean = false;
  invalidFormatError: boolean = false;
  imageToShow: string = '';
  formatsAllowed = ['.jpg', '.jpeg', '.png', '.gif'];
  uploading = false;

  file: any;
  fileName: string = '';

  private subscriptions: Subscription = new Subscription();

  @ViewChild('imageInput', { static: true })
  imageInput!: ElementRef;
  @ViewChild('imageCropModal', { static: false })
  imageCropModal?: ImageCropModalComponent;

  constructor(
    private helperService: HelperService,
    private storageManager: StorageManagerService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['imageUrl'] && changes['imageUrl'].currentValue) {
      this.imageToShow =
        this.imageUrl.indexOf('fender-black.svg') < 0
          ? this.imageUrl
          : 'assets/images/fender-black.svg';
    }

    if (
      (changes['imageFormControl'] || changes['required']) &&
      this.imageFormControl
    ) {
      // this.imageFormControl.setValidators(
      //   this.required ? [requiredValidator()] : null
      // );

      this.imageFormControl.updateValueAndValidity();
      if (this.required) {
        this.imageFormControl.markAsTouched();
      } else {
        this.imageFormControl.markAsUntouched();
      }
    }

    if (changes['maxMBs'] && changes['maxMBs'].currentValue) {
      this.maxSize = this.maxMBs * 1024 * 1024;
    }
  }

  ngOnInit() {
    this.isFile = true;
    this.imageSourceType = 'file';
  }

  ngAfterViewInit() {}

  onSelectedSource(event: Event) {
    event.preventDefault();

    this.isFile = false;
    this.isUrl = false;
    this.imageUrlSource = '';

    if (this.imageSourceType === 'file') {
      this.isFile = true;
    }
    if (this.imageSourceType === 'url') {
      this.isUrl = true;
    }
  }

  onImageChanged(event: any) {
    if (
      event &&
      event.currentTarget &&
      event.currentTarget.files instanceof FileList &&
      event.currentTarget.files.length > 0
    ) {
      const file = event.currentTarget.files[0];
      this.file = file;
      if (
        file &&
        !this.haveFormatErrors(file.type) &&
        !this.haveSizeErrors(file.size)
      ) {
        if (this.withCropper && file.type !== 'image/gif') {
          this.imageCropModal?.show();
        } else {
          if (file) {
            const fileReader = new FileReader();
            this.maxSizeError = false;

            this.subscriptions.add(
              fromEvent(fileReader, 'load').subscribe((loadEvent: any) => {
                this.onCropChanged(
                  (<FileReader>loadEvent.target).result as string
                );
              })
            );

            fileReader.readAsDataURL(file);
          }
        }
      } else {
        this.imageInput.nativeElement.value = null;
      }
    }
  }

  haveSizeErrors(size: number) {
    if (size <= this.maxSize) {
      return (this.maxSizeError = false);
    } else {
      setTimeout(() => {
        this.maxSizeError = false;
      }, 2000);
      return (this.maxSizeError = true);
    }
  }

  haveFormatErrors(type: string) {
    if (type.match(/image\/(png|jpg|jpeg|gif)$/i)) {
      return (this.invalidFormatError = false);
    } else {
      setTimeout(() => {
        this.invalidFormatError = false;
      }, 2000);
      return (this.invalidFormatError = true);
    }
  }

  onCropChanged(imageCropped: string) {
    if (this.useImageUploadService) {
      this.uploadImage(imageCropped);
      this.imageFormControl.setValue(imageCropped);
      this.imageFormControl.markAsDirty();
      this.imageFormControl.updateValueAndValidity();
      this.imageInput.nativeElement.value = '';
    } else {
      this.imageFormControl.setValue(imageCropped);
      this.imageFormControl.updateValueAndValidity();
      this.imageFormControl.markAsDirty();
      this.imageInput.nativeElement.value = '';
    }
  }

  onRemove() {
    const image: IEventImage = this.imageFormControl.value;
    image.url = '';
    image.deleted = true;
    this.imageFormControl.setValue(image);
    this.imageInput.nativeElement.value = '';
    this.imageToShow = '';
  }

  async uploadImage(imageCropped: any) {
    const type = imageCropped.split(';')[0].split('/')[1];

    let cleanFilename = this.helperService.getCleanFileName(
      this.file.name,
      type
    );

    if (cleanFilename) {
      const file = await fetch(imageCropped)
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], cleanFilename!, { type: type });
        });

      const uploaded = await this.storageManager.uploadFile(
        cleanFilename,
        file
      );
      this.uploadedFileName.emit(uploaded);
    }
  }

  onCropImageUrl(event: Event) {
    event.preventDefault();

    this.errorImageUrlRequired = false;
    this.errorImageUrlInvalid = false;

    if (!this.imageUrlSource) {
      this.errorImageUrlRequired = true;
      return;
    }

    if (
      this.imageUrlSource
      // &&
      // !this.imageUrlSource.match(this.helperService.urlPattern)
    ) {
      this.errorImageUrlInvalid = true;
      return;
    }

    this.imageCropModal?.show();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
