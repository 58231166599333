import { GoogleAnalyticsService } from './../../../services/common/google-analytics.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { IUser } from 'src/app/models/user';
import { IHelpVideo } from 'src/app/models/videos';
import { AuthenticationService } from 'src/app/services/users/authentication.service';
import { UserService } from '../../../services/users/user.service';

import { take } from 'rxjs';
import { IdentityService } from '../../../services/common/identity.service';

@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
})
export class HelpPageComponent implements OnInit {
  // Video Modal
  @ViewChild('videoPlayer', { static: false })
  videoPlayerModal?: ModalDirective;
  token: any;
  selectedVideoTitle: string = '';
  selectedVideoUrl: string = '';
  canEdit: boolean = false;
  userId!: string;
  mUser!: IUser;

  public selectedVideo: IHelpVideo | undefined;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private identityService: IdentityService
  ) {}

  async ngOnInit() {
    const user = await this.authenticationService.getCurrentAuthUser();
    this.token = user.signInUserSession.idToken.jwtToken;

    // Get Me
    this.identityService.getCurrentMeUser().subscribe((user) => {
      if (user) {
        this.mUser = user;
        this.checkRoleForEdit(this.mUser.role);
      }
    });
  }

  checkRoleForEdit(role: string | undefined) {
    if (role === 'FMIC-Admin' || role === 'FMIC-Customer-Service') {
      this.canEdit = true;
    }
  }

  clearSelectedVideo() {
    this.videoPlayerModal?.hide();
    this.selectedVideo = undefined;
    this.selectedVideoUrl = '';
  }

  onSelectVideo(videoSelected: IHelpVideo) {
    this.googleAnalyticsService.trackEvent(
      'faq',
      'Click Video',
      videoSelected.title
    );
    this.selectedVideoTitle = videoSelected.title;
    this.selectedVideoUrl = videoSelected.url;
    this.videoPlayerModal?.show();
    this.videoPlayerModal?.onHide.pipe(take(1)).subscribe(() => {
      this.clearSelectedVideo();
    });
  }
}
