import {
  Component,
  OnDestroy,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { stringify } from 'querystring';
import { IRole } from 'src/app/models/role';

@Component({
  selector: 'app-reactive-role-selector',
  templateUrl: './reactive-role-selector.component.html',
})
export class ReactiveRoleSelectorComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() splitRoles = false;
  @Input() parentForm!: FormGroup;
  @Input() preSelectedRolesIds: string[] = [];
  @Input() isRequired = false;
  @Input() useRoleObjectAsValue = false;
  @Input() returnSupplierId = true;

  public rolesForRoleSelector: { id: number }[] = [];
  initialRolesId: string[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['preSelectedRolesIds'] &&
      changes['preSelectedRolesIds'].currentValue
    ) {
      this.rolesForRoleSelector = this.parseRolesForRoleSelector(
        this.preSelectedRolesIds
      );
    }
  }

  ngOnInit() {
    if (!this.parentForm.contains('roles')) {
      this.parentForm.addControl('roles', new FormControl(''));
    }
    this.initialRolesId = this.preSelectedRolesIds;
  }

  parseRolesForRoleSelector(roles: string[]) {
    return roles.map((role) => ({ id: Number(role) }));
  }

  setRolesValue(selectedRoles: IRole[]) {
    // Sets supplierId as the form control value
    if (this.returnSupplierId) {
      this.parentForm.controls['roles'].setValue(
        this.useRoleObjectAsValue
          ? selectedRoles
          : selectedRoles.map((role) => role.id)
      );
      // Sets supplierCode as the form control value
    } else {
      this.parentForm.controls['roles'].setValue(
        this.useRoleObjectAsValue
          ? selectedRoles
          : selectedRoles.map((role) => role.code.toString())
      );
    }

    let rolesDirty = this.markRolesDirty(selectedRoles);

    if (rolesDirty) {
      this.parentForm.controls['roles'].markAsDirty();
    } else {
      this.parentForm.controls['roles'].markAsPristine();
    }
  }

  markRolesDirty(selectedRoles: IRole[]) {
    const selectedRolesID = selectedRoles.map((role: IRole) =>
      role.id.toString()
    );
    let pristine =
      selectedRolesID.length == this.initialRolesId.length &&
      selectedRolesID.every(
        (element, index) => element == this.initialRolesId[index]
      );
    return !pristine;
  }

  ngOnDestroy() {
    this.parentForm.removeControl('roles');
  }
}
