<div class="card">
  <div class="card-header dark-header-lg">
    <h3 class="f-font f-bold m-0">How To Videos</h3>
    <button
      [hidden]="!canEdit"
      class="f-btn f-blue f-xs m-0"
      (click)="onNewVideo()"
    >
      <i class="fa-solid fa-plus me-1"></i>
      Add New
    </button>
  </div>
  <div class="card-body">
    <div>
      <div
        class="row"
        dragula="Videos"
        [(dragulaModel)]="helpVideos"
        (dragulaModelChange)="dragDropEvent($event)"
      >
        <!-- Category Loading -->
        <ng-template [ngIf]="videosLoading">
          <div class="d-flex justify-content-center py-5">
            <app-fender-tone-loading-div
              [widget]="true"
              [black]="true"
            ></app-fender-tone-loading-div>
          </div>
        </ng-template>
        <!-- Videos -->
        <div
          class="d-flex justify-content-center col-12 col-md-3 col-lg-6 col-sm-4"
          [ngClass]="{ 'grabbable-pointer': canEdit }"
          *ngFor="let helpVideo of helpVideos"
        >
          <app-video-thumbnail
            [video]="helpVideo"
            [height]="'100%'"
            [width]="'100%'"
            [maxWidth]="'180px'"
            (onSelectVideo)="onSelectVideo(helpVideo)"
            (onEditVideo)="onEditVideo(helpVideo)"
            [canEdit]="canEdit"
          ></app-video-thumbnail>
        </div>
        <!-- IF NO VIDEOS - Message -->
        <ng-template [ngIf]="helpVideos.length < 1 && !videosLoading">
          <div
            class="pb-3 pt-5 d-flex justify-content-center empty-section-text"
          >
            <span class="f-font">No results found </span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<!-- === VIDEO - ADD EDIT - MODAL === -->
<div
  bsModal
  #videoModal="bs-modal"
  class="modal fade"
  role="dialog"
  [config]="{ backdrop: 'static' }"
  aria-labelledby="dialog-sizes-name1"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <!-- MODAL HEADER -->
      <div class="modal-header dark-header-lg">
        <h3 class="modal-title pull-left f-font">
          {{ modalOnEdit ? "Edit Video" : "Add Video" }}
        </h3>
        <div type="button" class="py-1 px-2" (click)="onDiscardVideo()">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
      <!-- MODAL BODY -->
      <div class="modal-body">
        <form [formGroup]="addEditVideo">
          <div class="mb-3">
            <label for="videoTitle" class="form-label f-font"
              >Video Title</label
            >
            <input
              id="videoTitle"
              type="text"
              class="form-control f-font"
              formControlName="title"
            />
          </div>
          <div class="mb-3">
            <label for="videoURL" class="form-label f-font">Video URL</label>
            <input
              id="videoURL"
              type="text"
              class="form-control f-font"
              formControlName="videoUrl"
              placeholder="https://"
            />
            <span class="f-font ms-1"
              ><small>Only video URLs from Cloudfront</small></span
            >
          </div>
        </form>
      </div>
      <!-- MODAL FOOTER -->
      <div class="modal-footer">
        <button
          type="button"
          class="f-btn f-xs f-red me-2"
          *ngIf="modalOnEdit"
          (click)="onDeleteVideo()"
        >
          <i class="fa-solid fa-trash-can me-0"></i>
        </button>
        <button
          *ngIf="!modalOnEdit"
          type="button"
          class="f-btn f-xs f-blue"
          [disabled]="!addEditVideo.valid || buttonLoading"
          (click)="saveNewVideo()"
        >
          <i class="fa-solid fa-plus me-1"></i
          ><span class="f-font">Add Video</span
          ><span class="ms-2" *ngIf="buttonLoading">
            <app-fender-tone-loading-div
              [inline]="true"
            ></app-fender-tone-loading-div>
          </span>
        </button>
        <button
          *ngIf="modalOnEdit"
          type="button"
          class="f-btn f-xs f-blue"
          [disabled]="
            !addEditVideo.valid || addEditVideo.pristine || buttonLoading
          "
          (click)="saveUpdatedVideo()"
        >
          <i class="fa-solid fa-floppy-disk me-2"></i
          ><span class="f-font">Save Video</span
          ><span class="ms-2" *ngIf="buttonLoading">
            <app-fender-tone-loading-div
              [inline]="true"
            ></app-fender-tone-loading-div>
          </span>
        </button>
        <div class="w-100">
          <!-- Success Alert - Video Created -->
          <div
            class="alert alert-success mt-3"
            role="alert"
            *ngIf="successAlert"
          >
            <h3 class="alert-heading f-font f-bold">
              <i class="fa-solid fa-check"></i>
              Video successfully created
            </h3>
            <p class="f-font mb-1">Your video has been added.</p>
          </div>
          <!-- GenericError Alert - Video Error -->
          <div
            class="alert alert-danger mt-3"
            role="alert"
            *ngIf="genericError"
          >
            <h3 class="alert-heading f-font f-bold">
              <i class="fa-solid fa-triangle-exclamation"></i>
              Unable to complete.
            </h3>
            <p class="f-font mb-1">We have ecounter an error.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ============= Notifications Alerts ============ -->
<!-- ALERT - DISCARD CHANGES -->
<app-notification-dialog
  [title]="'Alert'"
  [description]="notificationDialogMessage"
  *ngIf="showDiscardAlert"
  (confirmFunction)="confirmDiscardVideo($event)"
></app-notification-dialog>

<!-- ALERT - DELETE VIDEO -->
<app-notification-dialog
  [title]="'Alert'"
  [description]="notificationDialogMessage"
  *ngIf="showDeleteAlert"
  (confirmFunction)="confirmDeleteVideo($event)"
></app-notification-dialog>

<!-- ============= Toast Alerts ============ -->
<!-- Delete Toast -->
<app-toast-alert
  #deleteToast
  [selector]="'delete'"
  [title]="'Alert'"
  [description]="'Video successfully deleted'"
  [style]="'bg-success'"
></app-toast-alert>
