import { BitMovinVideoPlayerComponent } from './bitmovin-video-player.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  Component,
  OnInit,
  Output,
  Input,
  OnChanges,
  EventEmitter,
  ViewChild,
  TemplateRef,
  ElementRef,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'show-player',
  templateUrl: './show-player.component.html',
})
export class ShowPlayerComponent implements OnInit, OnChanges {
  @Input()
  widgetTitle!: string;
  @Input() videoTitle!: string;
  @Input() videoId!: string;
  @Input() eventId!: string | null;
  @Input() trackActivity!: boolean;
  @Input() videoUrl!: string;
  @Input() height = '550px';
  @Input() autoplay!: boolean;
  @Input() hideClose: boolean = false;
  @Input() videoActivity!: any;
  @Input() videoSubtitles!: any[];
  @Input() eventCategory!: string;
  @Input() pageSelected!: string | null;
  @Input() pageTitle!: string;
  @Input() startTime: number = 0;
  

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCloseVideo = new EventEmitter();
  @Output() activityChange = new EventEmitter();
  @Output() subtitleSelected = new EventEmitter();
  @Output() statusChanged = new EventEmitter();

  @ViewChild('videoPlayerModal', { static: true }) videoPlayerModal:
    | TemplateRef<ElementRef>
    | undefined;
  @ViewChild(BitMovinVideoPlayerComponent, { static: true }) bitMoving:
    | BitMovinVideoPlayerComponent
    | undefined;

  public modalRef: BsModalRef | undefined;

  public containerStyle = { height: this.height };

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['height'] && !changes['height'].currentValue) {
      this.height = '550px';
    }
  }

  closeVideo() {
    this.onCloseVideo.emit('onCloseVideo');
  }

  onActivityChange(activity: any) {
    this.activityChange.emit(activity);
  }

  onLanguageSelected(language: any) {
    this.subtitleSelected.emit(language);
  }

  onStatusChanged(status: any) {
    this.statusChanged.emit(status);
  }
}
