import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { IUser } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/users/authentication.service';
import { UserService } from 'src/app/services/users/user.service';
import { HelperService } from '../../services/helper/helper.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
})
export class MainHeaderComponent implements OnInit {
  headerBackgroundColor: string = '';

  menuActive: boolean = false;
  mobileSearch: boolean = false;
  headerSearch: FormGroup;

  mUser!: IUser;

  imagePathLogo: string =
    '../../../assets/images/Fender_Logo_Transparent-White.png';

  constructor(
    private route: Router,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.headerSearch = this.formBuilder.group({
      searchQuery: new FormControl('', Validators.required),
    });
  }
  ngOnInit() {
    this.headerBackgroundColor = this.helperService.getEnviromentColor(
      environment.environment
    );
  }

  toggleSearch() {
    this.menuActive = false;
    this.mobileSearch = !this.mobileSearch;
  }

  searchQuery() {
    console.log(this.headerSearch.value.searchQuery);
    this.headerSearch.reset();
  }
  async logout() {
    await this.authenticationService.signOut();
    this.route.navigateByUrl('/login');
  }
}
