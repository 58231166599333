import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';
import { PurchaseOrdersService } from '../../../services/purchase-orders/purchase-orders.service';
import {
  IStatusParams,
  PoStatus,
  PoStatusEndpointValue,
} from 'src/app/models/purchase-orders';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { GoogleAnalyticsService } from 'src/app/services/common/google-analytics.service';

@Component({
  selector: 'app-accept-reject-select',
  templateUrl: './accept-reject-select.component.html',
  styles: [],
})
export class AcceptRejectSelectComponent implements OnInit {
  @Input() supplierNumber!: string | undefined;
  @Input() existingStatus!: string | undefined;
  @Input() existingNote!: string | undefined;
  @Input() poNumber!: string | null;
  @Input() isFmicUser: boolean = false;
  @Input() userDateFormat: string | undefined;
  @Input() originalShipDate!: string;
  @Input() existingRequestedShipDate: string | undefined;
  @Input() updateRequired!: boolean;

  @Output() refreshStatus = new EventEmitter();
  @Output() setLineEditingModeAction = new EventEmitter();

  // states
  status: string = '';
  loadingSubmition: boolean = false;
  submittedAlert: boolean = false;

  // Status
  statusOptinos: any[] = [
    { value: PoStatus.ACCEPT, text: 'Accept' },
    { value: PoStatus.REJECT, text: 'Reject' },
    { value: PoStatus.REQ_DATE_CHANGE, text: 'Request Date Change' },
    { value: PoStatus.REQ_LINE_ITEM_CHANGE, text: 'Request Line Item Change' },
    { value: PoStatus.UPDATE, text: 'Update' },
  ];
  visibleStatusOptions: any[] = [];

  // Note State
  noteString: string = '';
  noteControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(500),
  ]);
  // Note Modal UI
  @ViewChild('noteModal', { static: true })
  noteModal!: TemplateRef<any>;
  noteModalRef!: BsModalRef;
  // Date Modal UI
  @ViewChild('dateChangeModal', { static: true })
  dateChangeModal!: TemplateRef<any>;

  dateChangeModalRef!: BsModalRef;
  lineItemChangeModalRef!: BsModalRef;
  // DatePicker Config
  datePickerConfig: Partial<BsDatepickerConfig>;
  dateChangeControl = new FormControl('', [Validators.required]);
  requestedDateChange: string = '';
  minDate: Date = new Date();
  // Request Line Change
  @ViewChild('confirmLineItemChangeModal', { static: true })
  confirmLineItemChangeModal!: TemplateRef<any>;
  requestedLineItemsChange: any[] = [];

  constructor(
    private modalService: BsModalService,
    private purchaseOrdersService: PurchaseOrdersService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.datePickerConfig = Object.assign(
      {},
      { containerClass: 'theme-red', showWeekNumbers: false }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['existingStatus'].currentValue && this.existingStatus) {
      this.setExistingStatus(this.existingStatus);
    }
    this.setStatusOptions();
  }

  ngOnInit(): void {}

  onLineItemAction(action: string) {
    console.log('ACTION', action);
  }

  setStatusOptions() {
    // No FMIC User
    if (!this.isFmicUser) {
      this.visibleStatusOptions = this.statusOptinos.filter(
        (option) => option.value !== PoStatus.UPDATE
      );
    }
    // FMIC User
    if (this.isFmicUser && this.updateRequired) {
      this.visibleStatusOptions = this.statusOptinos.filter(
        (option) => option.value == PoStatus.UPDATE
      );
    } else if (this.isFmicUser && !this.updateRequired) {
      this.visibleStatusOptions = this.statusOptinos;
    }
  }

  setExistingStatus(status: string) {
    if (status !== PoStatus.NEW) {
      this.status = status;
    }
    if (status == PoStatus.REJECT) {
      this.noteControl.setValue(this.existingNote);
    }
  }

  startsAsEditingStauts(status: string) {
    this.setOnEdit();
    if (status === PoStatus.REQ_LINE_ITEM_CHANGE) {
      this.setExistingStatus(PoStatus.REQ_LINE_ITEM_CHANGE);
      this.statusChanged(PoStatus.REQ_LINE_ITEM_CHANGE);
    }
  }

  statusChanged(value: any) {
    this.googleAnalyticsService.trackEvent(
      'purchase_orders',
      'Status Selection',
      value
    );
    if (value === PoStatus.ACCEPT) {
      this.saveSatus('accept');
    }
    if (value === PoStatus.REJECT) {
      this.showNoteModal(this.noteModal);
    }
    if (value === PoStatus.UPDATE) {
      this.saveSatus('update');
    }
    if (value === PoStatus.REQ_DATE_CHANGE) {
      this.showDateChangeModal(this.dateChangeModal);
    }
    if (value === PoStatus.REQ_LINE_ITEM_CHANGE) {
      this.setLineEditingModeAction.emit({ action: 'onEdit', value: true });
    }
  }

  setOnEdit() {
    this.status = '';
    this.noteString = '';
    this.requestedDateChange = '';
    this.existingNote = '';
    this.existingRequestedShipDate = '';
    this.noteControl.setValue('');
    this.dateChangeControl.setValue('');
  }

  showNoteModal(noteModal: TemplateRef<any>) {
    this.noteModalRef = this.modalService.show(noteModal);
    this.modalService.onHide.pipe(take(1)).subscribe((reason) => {
      if (reason === 'backdrop-click') {
        this.closeNoteModal();
      }
    });
  }

  closeNoteModal() {
    this.noteModalRef.hide();
    if (!this.noteString && !this.existingNote) {
      this.setOnEdit();
    }
    if ((this.noteString && !this.noteControl.pristine) || this.existingNote) {
      this.noteControl.setValue(this.noteString || this.existingNote);
      this.noteControl.markAsPristine();
    }
  }
  // ====== REQUEST DATE CHANGE =========

  showDateChangeModal(dateChangeModal: TemplateRef<any>) {
    if (this.existingRequestedShipDate) {
      this.googleAnalyticsService.trackEvent(
        'purchase_orders',
        'Request Date Change',
        'Show Dates'
      );
    }
    this.dateChangeModalRef = this.modalService.show(dateChangeModal);
    this.modalService.onHide.pipe(take(1)).subscribe((reason) => {
      if (reason === 'backdrop-click') {
        this.closeDateChangeModal();
      }
    });
  }

  onDateValueChange(value: Date): void {
    this.requestedDateChange = value.toISOString().split('T')[0];
  }

  closeDateChangeModal() {
    this.dateChangeModalRef.hide();
    this.submittedAlert = false;
    if (!this.existingRequestedShipDate) {
      this.dateChangeControl.setValue(this.noteString);
      this.dateChangeControl.markAsPristine();
      this.requestedDateChange = '';
      this.setOnEdit();
    }
  }

  saveDateChangeRequest() {
    this.googleAnalyticsService.trackEvent(
      'purchase_orders',
      'Request Date Change',
      'Submit'
    );
    this.saveSatus(PoStatusEndpointValue.REQ_DATE_CHANGE);
  }

  //  ====== REQUEST LINE ITEM CHANGE =========

  showLineItemChangeModal(lineItemChangeModal: TemplateRef<any>) {
    this.lineItemChangeModalRef = this.modalService.show(lineItemChangeModal);
    this.modalService.onHide.pipe(take(1)).subscribe((reason) => {
      if (reason === 'backdrop-click') {
        this.closeLineItemChangeModal();
      }
    });
  }

  closeLineItemChangeModal() {
    this.lineItemChangeModalRef.hide();
    this.submittedAlert = false;
  }

  cancelLineItemChange() {
    this.setOnEdit();
  }

  onSaveLineItemRequest(lineItemArray: any[]) {
    this.googleAnalyticsService.trackEvent(
      'purchase_orders',
      'Request Line Item Change',
      'Edit Mode - Save'
    );
    this.requestedLineItemsChange = lineItemArray;
    this.showLineItemChangeModal(this.confirmLineItemChangeModal);
  }

  saveLineItemChangeRequest() {
    this.googleAnalyticsService.trackEvent(
      'purchase_orders',
      'Request Line Item Change',
      'Attention Pop Up - Submit'
    );
    this.saveSatus(PoStatusEndpointValue.REQ_LINE_ITEM_CHANGE);
  }

  saveNote() {
    this.noteString = this.noteControl.value;
    this.saveSatus(PoStatusEndpointValue.REJECT);
  }

  setParam(status: string) {
    let paramObj: any = {};
    switch (status) {
      case PoStatusEndpointValue.REJECT:
        paramObj.note = this.noteString;
        break;
      case PoStatusEndpointValue.REQ_DATE_CHANGE:
        paramObj.requestedShipDate = this.requestedDateChange;
        break;
      case PoStatusEndpointValue.REQ_LINE_ITEM_CHANGE:
        paramObj.items = this.requestedLineItemsChange;
        break;
    }
    return paramObj;
  }

  saveSatus(status: string) {
    this.loadingSubmition = true;
    const params = this.setParam(status);
    if (this.supplierNumber && this.poNumber) {
      this.purchaseOrdersService
        .savePoStatus(this.supplierNumber, this.poNumber, status, params)
        .subscribe({
          next: () => {},
          error: () => {
            this.loadingSubmition = false;
          },
          complete: () => {
            this.cleanFormsAndValues(status);
          },
        });
    }
  }

  cleanFormsAndValues(status: string) {
    this.refreshStatus.emit();
    this.loadingSubmition = false;
    switch (status) {
      case PoStatusEndpointValue.REJECT:
        this.closeNoteModal();
        this.noteControl.markAsPristine();
        break;
      case PoStatusEndpointValue.REQ_DATE_CHANGE:
        this.dateChangeControl.markAsPristine();
        this.submittedAlert = true;
        break;
      case PoStatusEndpointValue.REQ_LINE_ITEM_CHANGE:
        this.setLineEditingModeAction.emit({ action: 'onEdit', value: false });
        this.submittedAlert = true;
        break;
    }
  }
}
