import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FenderRoles, ROLES } from 'src/app/models/role';
import { IdentityService } from 'src/app/services/common/identity.service';
import { PurchaseOrdersService } from 'src/app/services/purchase-orders/purchase-orders.service';
import { IUser } from '../../../models/user';
import { SelectSupplierHelperService } from '../../../services/helper/select-supplier-helper.service';
import {
  CurrencyCode,
  IDestinationData,
  ILineItemCompValues,
  ILineItemParam,
  IPoFilterParams,
  IPoStatusData,
  IPurchaseOrder,
  PoStatus,
} from '../../../models/purchase-orders';
import { Subscription, take } from 'rxjs';
import { GoogleAnalyticsService } from 'src/app/services/common/google-analytics.service';
import { ToastAlertComponent } from 'src/app/ui/toast-alert/toast-alert.component';
import { WebsocketService } from '../../../services/lib/websocket.service';
import { HelperService } from '../../../services/helper/helper.service';
import { TimeoutConfig } from 'src/app/fixtures/app-settings/constants';
import { AcceptRejectSelectComponent } from 'src/app/ui/selects/accept-reject-select/accept-reject-select.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-purchase-order-details',
  templateUrl: './purchase-order-details.component.html',
})
export class PurchaseOrderDetailsComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private purchaseOrdersService: PurchaseOrdersService,
    private identityService: IdentityService,
    private selectSupplierHelperService: SelectSupplierHelperService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private websocketService: WebsocketService,
    private helperService: HelperService,
    private modalService: BsModalService,
    private currencyPipe: CurrencyPipe
  ) {}

  // User Data
  mUser!: IUser;
  userDateFormat: string = '';
  currentSupplierNumber: string | undefined = '';
  poNumber: string | null = '';
  subscription: Subscription = new Subscription();
  isFmicUser: boolean = false;
  currencyArray: any = Object.values(CurrencyCode);

  // Purchaser Data
  purchaserInfo: any = {};

  // Status Values
  existingStatus: string | undefined;
  existingStatusObject: any;
  existingNote: string | undefined;
  statusCreatedAt: string | undefined;
  statusUserName: string | undefined;
  statusUserEmail: string | undefined;
  showBanner: boolean = false;
  bannerMessage: string | undefined;
  currentStatus: PoStatus | undefined;
  requestedShipDate: string | undefined;
  updateByFMICRequired: boolean = false;
  startOnEditModeStatus: string | null = null;
  //PO Data
  purchaseOrderList: any[] = [];
  poOpenQtyTotal: string = '';
  poOpenValueTotal: string = '';
  poCurrency: string = '';
  poAddress: string = '';
  purchaseOrderEditedDataList: any[] = [];

  // Values for Accept/Reject Component
  poItem: string | undefined = '';
  scheduleLine: string | undefined = '';

  // Filters

  poFilterParams: IPoFilterParams = {};

  sort: { by: string; type: string } = {
    by: '',
    type: '',
  };

  // Loading
  loadingReport: any = {
    loader: false,
  };
  loadingPoPdf: any = {
    loader: false,
  };
  loadingData: boolean = true;
  loadingTable: boolean = true;
  hasPoData: boolean = false;
  emptyResult: boolean = false;
  toastErrorText: string = '';

  // Line Item Editing
  isLineItemEditingMode: boolean = false;
  lineItemCommentIndex: number = 0;
  lineItemFormArray = new FormArray([], Validators.required);
  lineItemCompValues: ILineItemCompValues[] | any[] = [];
  lineItemNoteModalReadOnly: boolean = false;
  showAllLineItemRequests: boolean = false;

  // Regex
  netPriceInputRegex: string = '';

  // Accept / Reject Component (Child)
  @ViewChild(AcceptRejectSelectComponent, { static: false })
  statusDropdownComp?: AcceptRejectSelectComponent;

  // Note Modal UI
  @ViewChild('noteModal', { static: true })
  noteModal!: TemplateRef<any>;
  noteModalRef!: BsModalRef;
  @ViewChild('readOnlyNoteModal', { static: true })
  readOnlyNoteModal!: TemplateRef<any>;
  readOnlyNoteModalRef!: BsModalRef;

  // Toast Components
  @ViewChild('warningToast') warningToastAlert!: ToastAlertComponent;

  getFormControlAt(index: number, control: string) {
    return this.lineItemFormArray.at(index).get(control) as FormControl;
  }
  getEditigLineInfoAt(index: number, type?: string, key?: string) {
    switch (type) {
      case 'Line':
        return (
          this.lineItemCompValues[index] !== null &&
          this.lineItemCompValues[index] !== undefined
        );
      case 'Field':
        return (
          this.lineItemCompValues[index] !== null &&
          this.lineItemCompValues[index] !== undefined &&
          key &&
          key in this.lineItemCompValues[index]
        );

      case 'Value':
        return this.lineItemCompValues[index][key!];
    }
  }

  ngOnInit() {
    this.poNumber = this.getIdRouteParameter();
    this.getUserInfo();
    this.onMessageHandlerErrorHandler();
  }

  getUserInfo() {
    this.identityService.getCurrentMeUser().subscribe({
      next: (user) => {
        if (user) {
          this.mUser = user;
          this.userDateFormat = this.mUser.dateFormat
            ? this.mUser.dateFormat
            : 'MM-dd-yyyy';
          this.getUserSupplierNumber(this.mUser);
          this.checkIfFmicUser(this.mUser);
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  changeSort(sort: any) {
    this.sort.type =
      this.sort.by === sort && this.sort.type === 'desc' ? 'asc' : 'desc';
    this.sort.by = sort;
    this.getPurchaseOrder(this.currentSupplierNumber, this.poNumber);
  }

  checkIfFmicUser(user: IUser) {
    const role = user.role as any;
    const fenderRoles = Object.values(FenderRoles);
    if (fenderRoles.includes(role)) {
      this.isFmicUser = true;
    }
  }

  getUserSupplierNumber(user: IUser) {
    const role = user.role;
    if (role === ROLES.FMIC_ADMIN || ROLES.FMIC_CUSTOMER_SERVICE) {
      const suppNumberParam = this.getSupplierNumberParameter();
      this.startOnEditModeStatus = this.getStartOnEditParameter();
      if (suppNumberParam) {
        this.currentSupplierNumber = suppNumberParam;
      } else {
        this.selectSupplierHelperService.getValue().subscribe((value) => {
          this.currentSupplierNumber = value;
        });
      }
    }
    if (
      role === ROLES.SUPPIER_ADMIN ||
      role === ROLES.SUPPIER_MANAGER ||
      role === ROLES.SUPPIER_FINANCE
    ) {
      this.currentSupplierNumber = user.supplierNumber;
    }
    this.getPurchaseOrder(this.currentSupplierNumber, this.poNumber);
    this.getDestination(this.currentSupplierNumber, this.poNumber);
    this.getStatus();
  }

  setAddress(destinationData: IDestinationData) {
    this.poAddress = `${destinationData.name || ''}
    ${destinationData.address || ''}
    ${destinationData.city || ''} ${destinationData.region || ''} ${
      destinationData.zip || ''
    }
    ${destinationData.country || ''}`;
  }

  setPoTotals(itemsList: IPurchaseOrder[]) {
    let currency = itemsList[0]?.currency || '';
    let valueSum = 0;
    let qtySum = 0;
    itemsList.forEach((item) => {
      valueSum += Number(item.openValue);
      qtySum += Number(item.openQuantity);
    });
    this.poOpenValueTotal = valueSum.toString();
    this.poCurrency = currency;
    this.netPriceInputRegex = this.helperService.setCurrencyRegex(currency);
    this.poOpenQtyTotal = qtySum.toString();
  }

  setPurchaserInfo(destinationData: IDestinationData) {
    this.purchaserInfo.email = destinationData.emailId;
    if (destinationData.purchaserName) {
      this.purchaserInfo.name = destinationData.purchaserName;
    }
  }

  getStatus() {
    this.purchaseOrdersService
      .getPoStatus(this.currentSupplierNumber, this.poNumber)
      ?.subscribe({
        next: (value) => {
          this.checkStatusValues(value);
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  getDestination(supplierNumber: string | undefined, poNumber: string | null) {
    if (supplierNumber && poNumber) {
      this.purchaseOrdersService
        .getDestination(supplierNumber, poNumber)
        .subscribe({
          next: (value) => {
            this.setPurchaserInfo(value);
            this.setAddress(value);
          },
          error: (err) => {
            console.error(err);
          },
        });
    }
  }

  getPurchaseOrder(
    supplierNumber: string | undefined,
    poNumber: string | null
  ) {
    if (!this.hasPoData) {
      this.loadingData = true;
    }
    this.loadingTable = true;
    if (supplierNumber && poNumber) {
      this.purchaseOrdersService
        .getPoDetails(supplierNumber, poNumber, this.sort.by, this.sort.type)
        .subscribe({
          next: (value) => {
            if (value.length > 0) {
              this.purchaseOrderList = value;
              this.setPoTotals(value);
            } else {
              this.emptyResult = true;
            }
            this.loadingData = false;
            this.loadingTable = false;
          },
          complete: () => {
            if (!this.emptyResult) {
              this.hasPoData = true;
            }
            if (this.existingStatus == PoStatus.REQ_LINE_ITEM_CHANGE) {
              this.setLineItemEditedData();
            }
            this.loadingData = false;
            this.loadingTable = false;
            this.startStatusEditMode();
          },
          error: (err) => {
            console.error(err);
          },
        });
    }
  }

  checkStatusValues(status: IPoStatusData) {
    this.existingStatusObject = status;
    if (status.status) {
      this.existingStatus = status.status;
      if (status.status !== PoStatus.NEW) {
        this.showBanner = true;
        this.statusUserEmail = status.updatedByEmail;
        this.statusCreatedAt = status.updatedOn;
        this.statusUserName = status.updatedByName;
        this.currentStatus = status.status as PoStatus;

        this.setBannerMsg();
      }
    }
    //  Rejected
    if (status.status === PoStatus.REJECT && status.note) {
      this.existingNote = status.note;
    }
    //  Requested Date Change
    if (
      status.status === PoStatus.REQ_DATE_CHANGE &&
      status.requestedShipDate
    ) {
      this.requestedShipDate = status.requestedShipDate;
      this.updateByFMICRequired = true;
    }
    // Requested Line Item Change
    if (status.status === PoStatus.REQ_LINE_ITEM_CHANGE) {
      if (this.purchaseOrderList.length) {
        this.setLineItemEditedData();
      }
      this.updateByFMICRequired = true;
    }
    // Updated
    if (status.status === PoStatus.UPDATE) {
      this.setLineEditingModeAction({ action: 'onUpdate' });
      this.updateByFMICRequired = false;
    }
  }

  startStatusEditMode() {
    if (this.startOnEditModeStatus == PoStatus.REQ_LINE_ITEM_CHANGE) {
      setTimeout(() => {
        this.statusDropdownComp?.startsAsEditingStauts(
          PoStatus.REQ_LINE_ITEM_CHANGE
        );
      }, 1);
    }
  }

  setBannerMsg() {
    if (
      this.showBanner &&
      this.statusCreatedAt &&
      this.statusUserName &&
      this.statusUserEmail
    ) {
      this.bannerMessage = `${this.formatStatusCreatedAt(
        this.statusCreatedAt,
        this.currentStatus
      )} by ${this.statusUserName} - ${this.statusUserEmail}`;
    }
  }

  downloadDetailsReport() {
    this.loadingReport.loader = true;
    this.loadingReport.timer = setTimeout(() => {
      this.loadingReport.loader = false;
    }, TimeoutConfig.WEBSOCKET_LOADER);
    if (!this.currentSupplierNumber || !this.poNumber) {
      return null;
    }
    this.googleAnalyticsService.trackEvent(
      'purchase_orders',
      'Click',
      'Export Order Details'
    );
    this.purchaseOrdersService
      .downloadDetailsReport(this.setFilterParams())
      .subscribe({
        next: (data) => {
          if (data && data.url) {
            this.helperService.downloadFromUrl(data.url);
            this.loadingReport.loader = false;
            clearTimeout(this.loadingReport.timer);
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  setFilterParams() {
    return {
      supplierNumber: this.currentSupplierNumber,
      poNumber: this.poNumber,
      sortBy: this.sort.by,
      sortType: this.sort.type,
    };
  }

  downloadDetailsPdf() {
    this.loadingPoPdf.loader = true;
    this.loadingPoPdf.timer = setTimeout(() => {
      this.loadingPoPdf.loader = false;
    }, TimeoutConfig.WEBSOCKET_LOADER);
    this.googleAnalyticsService.trackEvent(
      'purchase_orders',
      'Click',
      'PDF Download'
    );
    if (!this.poNumber) {
      return null;
    }
    this.subscription.add(
      this.purchaseOrdersService.downloadPoPdf(this.poNumber).subscribe({
        next: (data) => {
          if (data && data.url) {
            this.helperService.downloadFromUrl(data.url);
            this.loadingPoPdf.loader = false;
          }
          if (data.message === 'No document found') {
            this.toastErrorText = data.message;
            this.warningToastAlert?.show();
            this.loadingPoPdf.loader = false;
            clearTimeout(this.loadingPoPdf.timer);
          }
        },
        error: (err) => {
          console.error(err);
        },
      })
    );
  }

  transformCurrencyValue(value?: string) {
    const currency = this.poCurrency || null;
    if (currency && this.currencyArray.includes(currency)) {
      return this.currencyPipe.transform(
        value,
        this.returnCurrencyFormat(currency, 'code'),
        'symbol',
        this.returnCurrencyFormat(currency, 'decimal')
      );
    } else {
      return value;
    }
  }

  returnCurrencyFormat(currency: string, formatType: string) {
    switch (formatType) {
      case 'code':
        return this.purchaseOrdersService.getCurrencyCode(currency);
      case 'decimal':
        return this.purchaseOrdersService.getCurrencyDecimal(currency);
      default:
        return '';
    }
  }

  // Line Item Change Request ==========

  setLineEditingModeAction(event: any) {
    const { action, value } = event;
    switch (action) {
      case 'onEdit':
        this.isLineItemEditingMode = value;
        if (value) {
          this.setFormsForEditing();
        } else {
          this.lineItemFormArray.clear();
          this.lineItemFormArray.markAsPristine();
          this.isLineItemEditingMode = false;
          this.statusDropdownComp?.cancelLineItemChange();
        }
        break;
      case 'onUpdate':
        this.lineItemFormArray = new FormArray([], Validators.required);
        this.lineItemCompValues = [];
        this.isLineItemEditingMode = false;
        break;
    }
  }

  setFormsForEditing() {
    this.purchaseOrderList.forEach((poLine: IPurchaseOrder) => {
      const lineItemFormGroup = new FormGroup({
        poItem: new FormControl(poLine.poItem),
        openQuantity: new FormControl(poLine.openQuantity, [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(10),
        ]),
        netPrice: new FormControl(
          this.transformCurrencyValue(poLine.netPrice),
          [
            Validators.required,
            Validators.pattern(this.netPriceInputRegex),
            Validators.maxLength(13),
          ]
        ),
        draft: new FormControl('', Validators.maxLength(500)),
        comment: new FormControl('', Validators.maxLength(500)),
      });
      (<FormArray>this.lineItemFormArray).push(lineItemFormGroup);
    });
  }

  saveLineItemChangeRequest() {
    const filteredArray = this.filterPristineLineItem();
    this.statusDropdownComp?.onSaveLineItemRequest(filteredArray);
  }

  filterPristineLineItem() {
    let filteredArray: any[] = [];
    this.lineItemFormArray.controls.forEach((lineItem) => {
      if (lineItem.dirty) {
        delete lineItem.value.draft;
        lineItem.value.netPrice = lineItem.value.netPrice.replace(
          /[^\d.]/g,
          ''
        );
        filteredArray.push(lineItem.value);
      }
    });
    return filteredArray;
  }

  setLineItemEditedData() {
    const editedData = this.existingStatusObject.items;
    this.purchaseOrderList.forEach((originalLine: IPurchaseOrder) => {
      let editedLine = editedData.find(
        (editedValues: ILineItemParam) =>
          editedValues.poItem === originalLine.poItem
      );
      if (editedLine) {
        this.lineItemCompValues.push(
          this.setLineItemComparsion(originalLine, editedLine)
        );
      } else {
        this.lineItemCompValues.push(null as any);
      }
    });
  }

  setLineItemComparsion(original: IPurchaseOrder, edited: ILineItemParam) {
    let comparisonObject: ILineItemCompValues = {};

    if (original.openQuantity && original.openQuantity != edited.openQuantity) {
      comparisonObject.openQuantity = {} as { original: string; new: string };
      comparisonObject.openQuantity.original = original.openQuantity.toString();
      comparisonObject.openQuantity.new = edited.openQuantity;
    }
    if (original.netPrice?.toString() && original.netPrice != edited.netPrice) {
      comparisonObject.unitPrice = {} as { original: string; new: string };
      comparisonObject.unitPrice!.original = original.netPrice.toString();
      comparisonObject.unitPrice!.new = edited.netPrice;
    }
    if (edited.comment) {
      comparisonObject.comment = edited.comment;
    }

    return comparisonObject as ILineItemCompValues;
  }

  showLineItemNoteModal(noteModal: TemplateRef<any>, index: number) {
    this.lineItemCommentIndex = index;
    this.noteModalRef = this.modalService.show(noteModal);
    this.modalService.onHide.pipe(take(1)).subscribe((reason) => {
      if (reason === 'backdrop-click') {
        this.closeNoteModal(index);
      }
    });
  }
  showReadOnlyNoteModal(
    readOnlyNoteModal: TemplateRef<any>,
    index: number,
    showAll: boolean = false
  ) {
    this.googleAnalyticsService.trackEvent(
      'purchase_orders',
      'Request Line Item Change',
      showAll ? 'Show All Requests' : 'Show Comment'
    );
    this.lineItemCommentIndex = index;
    this.showAllLineItemRequests = showAll;
    this.readOnlyNoteModalRef = this.modalService.show(readOnlyNoteModal, {
      class: 'modal-lg',
    });
    this.readOnlyNoteModalRef.setClass('wide-modal-dialog');
    this.modalService.onHide.pipe(take(1)).subscribe((reason) => {
      if (reason === 'backdrop-click') {
        this.closeReadOnlyNoteModal(index);
      }
    });
  }

  saveLineItemNoteModal(index: number) {
    this.getFormControlAt(index, 'comment').patchValue(
      this.getFormControlAt(index, 'draft').value
    );
    this.closeNoteModal(index);
  }

  closeNoteModal(index: number) {
    if (
      this.getFormControlAt(index, 'comment').value !=
      this.getFormControlAt(index, 'draft').value
    ) {
      this.getFormControlAt(index, 'draft').patchValue(
        this.getFormControlAt(index, 'comment').value
      );
    }
    if (!this.getFormControlAt(index, 'draft').value.length) {
      this.getFormControlAt(index, 'draft').markAsPristine();
    }
    this.noteModalRef.hide();
    this.lineItemCommentIndex = 0;
  }

  closeReadOnlyNoteModal(index?: number) {
    this.readOnlyNoteModalRef.hide();
    this.lineItemCommentIndex = 0;
    this.showAllLineItemRequests = false;
  }

  onMessageHandlerErrorHandler() {
    this.subscription.add(
      this.websocketService
        .returnWebsocketMessageHandler()
        .subscribe((response: any) => {
          if (this.loadingReport.loader) {
            this.loadingReport = false;
            clearTimeout(this.loadingReport.timer);
            this.websocketService.setHandlerMessage('');
          }
          if (this.loadingPoPdf.loader) {
            this.loadingPoPdf = false;
            clearTimeout(this.loadingPoPdf.timer);
            this.websocketService.setHandlerMessage('');
          }
          if (response == 'No document found') {
            this.toastErrorText = response;
            this.warningToastAlert?.show();
            this.websocketService.setHandlerMessage('');
          }
        })
    );
  }

  private getIdRouteParameter() {
    return this.activatedRoute.snapshot.paramMap.has('poNumber')
      ? this.activatedRoute.snapshot.paramMap.get('poNumber')
      : null;
  }

  private getSupplierNumberParameter() {
    return this.activatedRoute.snapshot.paramMap.has('supplierNumber')
      ? this.activatedRoute.snapshot.paramMap.get('supplierNumber')
      : null;
  }

  private getStartOnEditParameter() {
    return this.activatedRoute.snapshot.paramMap.has('editingMode')
      ? this.activatedRoute.snapshot.paramMap.get('editingMode')
      : null;
  }

  private formatStatusCreatedAt(
    statusCreatedAt: string | number | Date,
    status: PoStatus | undefined | any
  ) {
    if (!statusCreatedAt) return null;
    if (
      !status ||
      status === PoStatus.NEW ||
      !Object.values(PoStatus).includes(status)
    )
      return null;

    let statusMsg: string = status;

    // Create a Date object from the timestamp
    const date = new Date(statusCreatedAt);

    // Extract components of the date
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // months are 0-indexed
    const day = date.getUTCDate().toString().padStart(2, '0');
    let hour = date.getUTCHours();
    const minute = date.getUTCMinutes().toString().padStart(2, '0');

    // Convert 24-hour time to 12-hour format
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12; // hour '0' should be '12'
    const formattedHour = hour.toString().padStart(2, '0');

    // Format the string
    const formattedString = `Last ${statusMsg} on ${month}-${day}-${year} ${formattedHour}:${minute} ${ampm} UTC`;
    return formattedString;
  }
}
