import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleAnalyticsService } from './../../../services/common/google-analytics.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription, filter } from 'rxjs';
import { IUser } from 'src/app/models/user';
import { ContactUsService } from 'src/app/services/contact-us/contact-us.service';
import { AuthenticationService } from 'src/app/services/users/authentication.service';
import { UserService } from 'src/app/services/users/user.service';
import { StorageManagerService } from 'src/app/services/managers/storage-manager.service';
import { ToastAlertComponent } from '../../toast-alert/toast-alert.component';
import { FenderRoles } from 'src/app/models/role';
import { SelectSupplierHelperService } from '../../../services/helper/select-supplier-helper.service';
import { IdentityService } from '../../../services/common/identity.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent implements OnInit {
  @ViewChild('contactUsFormModal', { static: false })
  contactUsFormModal?: ModalDirective;
  subscription: Subscription = new Subscription();

  // Arrays
  issueTypes: any;

  // Displays
  displayPurchaseOrders: boolean = false;
  displayInvoicePayments: boolean = false;
  displayShipmentQuestion: boolean = false;
  displaySuggestion: boolean = false;
  displayOther: boolean = false;

  // User Info
  mUser: IUser = {
    name: '',
    email: '',
    role: '',
  };
  userFirstName: string | undefined = '';
  userLastName: string | undefined = '';
  userInfo: any;
  supplierNumberValue: string | undefined = '';

  // File Names
  billOfLadingName: string = '';
  packingListName: string = '';

  // Regex
  customPatterns = { A: { pattern: new RegExp('^[0-9()+-]') } };

  // Alerts
  showSubmitAlert: boolean = false;
  showDiscardAlert: boolean = false;
  submitingLoading: boolean = false;
  genericError: boolean = false;

  // Toast Components
  @ViewChild('sentToast') sentAlert!: ToastAlertComponent;

  // Contact Us Form - MAIN FORM
  constactUsForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
    lastName: new FormControl('', Validators.maxLength(50)),
    email: new FormControl(),
    phoneNumber: new FormControl('', Validators.maxLength(50)),
    issueType: new FormControl('', Validators.required),
    comments: new FormControl('', Validators.maxLength(1000)),
  });

  // Shipment Question Form
  shipmentQuestionForm = new FormGroup({
    shipmentType: new FormControl('', Validators.required),
    shipmentItem: new FormControl('', Validators.required),
    containLiBatteries: new FormControl('', Validators.required),
    isDangerItem: new FormControl('', Validators.required),
    fromLocation: new FormControl('', [
      Validators.required,
      Validators.maxLength(100),
    ]),
    numberOfBoxes: new FormControl(''),
    weight: new FormControl('', Validators.maxLength(50)),
    dimension: new FormControl('', Validators.maxLength(50)),
    length: new FormControl('', Validators.maxLength(50)),
    width: new FormControl('', Validators.maxLength(50)),
    height: new FormControl('', Validators.maxLength(50)),
    brands: new FormArray([]),
    destinationLocation: new FormControl('', Validators.required),
  });

  invoicePaymentsForm = new FormGroup({
    purchaseOrderNumber: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
    invoiceNumber: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
      Validators.pattern('^[0-9]*$'),
    ]),
    billOfLading: new FormControl('', Validators.required),
    packingList: new FormControl('', Validators.required),
  });

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private identityService: IdentityService,
    private contactUsService: ContactUsService,
    private selectSupplierHelperService: SelectSupplierHelperService,
    private storageManagerService: StorageManagerService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {
    this.getCurrentUserInfo();
    this.getIssueTypes();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onContactUs() {
    this.contactUsFormModal?.show();
  }

  resetContactUsForm() {
    this.constactUsForm.reset({
      firstName: this.userFirstName,
      lastName: this.userLastName,
      email: this.mUser.email,
      issueType: '',
    });
    this.onTypeChange('');
  }

  removeIssueTypeChanges() {
    // Set All Displays to False
    this.displayPurchaseOrders = false;
    this.displayInvoicePayments = false;
    this.displayShipmentQuestion = false;
    this.displaySuggestion = false;
    this.displayOther = false;
    // Invoice Payemnts
    this.constactUsForm.removeControl('invoicePaymentsForm');
    // Shipment Question
    this.constactUsForm.removeControl('shipmentQuestionForm');
    // Purchase Orders Options
    this.constactUsForm.removeControl('purchaseOrderNumber');
    // Suggestion & Other Issues
    this.constactUsForm.controls['comments'].clearValidators();
    this.constactUsForm.controls['comments'].updateValueAndValidity();
  }

  onCloseModal() {
    if (this.constactUsForm.pristine) {
      this.contactUsFormModal?.hide();
      this.onTypeChange('');
    } else {
      this.showDiscardAlert = true;
    }
  }

  confirmCloseModal(confirmation: boolean) {
    this.showDiscardAlert = false;
    if (confirmation) {
      this.resetContactUsForm();
      this.contactUsFormModal?.hide();
      this.removeExistingFiles();
    }
  }

  removeExistingFiles() {
    if (this.billOfLadingName) {
      this.storageManagerService.removeFile(this.billOfLadingName);
    }
    if (this.packingListName) {
      this.storageManagerService.removeFile(this.packingListName);
    }
  }

  onTypeChange(type: string) {
    this.removeIssueTypeChanges();

    if (type == 'purchase-orders') {
      this.constactUsForm.addControl(
        'purchaseOrderNumber',
        new FormControl('', [Validators.required, Validators.maxLength(50)])
      );
      this.displayPurchaseOrders = true;
    } else if (type == 'invoice-payments') {
      // Add form grpup
      this.constactUsForm.addControl(
        'invoicePaymentsForm',
        this.invoicePaymentsForm
      );
      this.constactUsForm.controls[
        'invoicePaymentsForm'
      ].updateValueAndValidity();
      this.displayInvoicePayments = true;
    } else if (type == 'shipment-question') {
      // Add form grpup
      this.constactUsForm.addControl(
        'shipmentQuestionForm',
        this.shipmentQuestionForm
      );
      this.constactUsForm.controls[
        'shipmentQuestionForm'
      ].updateValueAndValidity();
      this.displayShipmentQuestion = true;
    } else if (type == 'suggestion') {
      this.constactUsForm.controls['comments'].addValidators(
        Validators.required
      );
      this.constactUsForm.controls['comments'].updateValueAndValidity();
      this.displaySuggestion = true;
    } else if (type == 'other') {
      this.constactUsForm.controls['comments'].addValidators(
        Validators.required
      );
      this.constactUsForm.controls['comments'].updateValueAndValidity();
      this.displayOther = true;
    }
  }

  parseFormData(form: any) {
    const parsingData = form;
    parsingData.supplierNumber = this.supplierNumberValue;
    if (form.issueType === 'invoice-payments') {
      parsingData.invoicePaymentsForm.billOfLading = `${this.userInfo.id}/${this.billOfLadingName}`;
      parsingData.invoicePaymentsForm.packingList = `${this.userInfo.id}/${this.packingListName}`;
    }
    if (form.issueType === 'shipment-question') {
      if (parsingData.shipmentQuestionForm.length) {
        parsingData.shipmentQuestionForm.dimension =
          `${parsingData.shipmentQuestionForm.dimension}` +
          `L:${parsingData.shipmentQuestionForm.length} `;
      }
      if (parsingData.shipmentQuestionForm.width) {
        parsingData.shipmentQuestionForm.dimension =
          `${parsingData.shipmentQuestionForm.dimension}` +
          `W:${parsingData.shipmentQuestionForm.width} `;
      }
      if (parsingData.shipmentQuestionForm.height) {
        parsingData.shipmentQuestionForm.dimension =
          `${parsingData.shipmentQuestionForm.dimension}` +
          `H:${parsingData.shipmentQuestionForm.height} `;
      }
      if (parsingData.shipmentQuestionForm.dimension) {
        parsingData.shipmentQuestionForm.dimension =
          parsingData.shipmentQuestionForm.dimension.replace(/\s+$/, '');
      }
    }
    return parsingData;
  }

  onSubmitForm() {
    this.showSubmitAlert = true;
  }

  confirmSubmitForm(confirmation: boolean) {
    this.submitingLoading = true;

    this.showSubmitAlert = false;
    if (confirmation) {
      const parsedData = this.parseFormData(this.constactUsForm.value);

      this.googleAnalyticsService.trackEvent(
        'contact_us',
        'Submit',
        parsedData.issueType
      );
      this.subscription.add(
        this.contactUsService.submitContactForm(parsedData).subscribe({
          next: () => {},
          complete: () => {
            this.sentAlert?.show();
            this.contactUsFormModal?.hide();
            this.submitingLoading = false;
            this.resetContactUsForm();
          },
          error: (err) => {
            this.submitingLoading = false;
            this.genericError = true;
            setTimeout(() => {
              this.genericError = false;
            }, 3000);
            this.removeExistingFiles();
            console.error(err);
          },
        })
      );
    }
  }

  async getCurrentUserInfo() {
    this.userInfo = await this.authenticationService.getUserInfo();
    this.identityService.getCurrentMeUser().subscribe((user) => {
      if (user) {
        this.setFormValuesWithUserInfo(user);
      }
    });
  }

  setFormValuesWithUserInfo(user: IUser) {
    this.mUser = user;
    this.userFirstName = this.mUser.name?.split(' ')[0];
    this.userLastName = this.mUser.name?.split(' ')[1];
    this.constactUsForm.get('firstName')?.setValue(this.userFirstName);
    this.constactUsForm.get('lastName')?.setValue(this.userLastName);
    this.constactUsForm.get('email')?.setValue(this.mUser.email);
    this.constactUsForm.markAsPristine();
    this.getSupplierNumber(user);
  }

  getSupplierNumber(user: IUser) {
    if (this.checkIfFmicUser(user)) {
      this.selectSupplierHelperService.getValue().subscribe((value) => {
        if (value) {
          this.supplierNumberValue = value;
        } else {
          this.supplierNumberValue = 'Fender User';
        }
      });
    } else {
      this.supplierNumberValue = user.supplierNumber;
    }
  }

  checkIfFmicUser(user: IUser) {
    const role = user.role as any;
    const fenderRoles = Object.values(FenderRoles);
    if (fenderRoles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }

  getIssueTypes() {
    this.subscription.add(
      this.contactUsService.getContactUsTypes().subscribe({
        next: (response: any) => {
          this.issueTypes = response;
        },
        complete: () => {},
        error: (error) => {
          console.error(error);
        },
      })
    );
  }

  getUploadedBillOfLadingName(evt: any) {
    this.billOfLadingName = evt;
  }
  getUploadedPackingListName(evt: any) {
    this.packingListName = evt;
  }
}
