import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/users/authentication.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  emailSent: boolean = false;
  isError: boolean = false;
  forgotPasswordForm: FormGroup;
  loading: boolean = false;
  constructor(
    private route: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.forgotPasswordForm = formBuilder.group({
      email: new FormControl('', [Validators.email, Validators.required]),
    });
  }

  ngOnInit(): void {}

  sendEmail() {
    this.loading = true;
    const username = this.forgotPasswordForm.get('email')?.value.toLowerCase();
    this.authenticationService.forgotPassword(username).then(
      (response) => {
        if (response) {
          this.emailSent = true;
          this.loading = false;
          setTimeout(() => {
            this.route.navigate(['reset-password'], {
              queryParams: { username: username },
            });
          }, 3000);
        }
      },
      (error) => {
        this.loading = false;
        this.isError = true;
      }
    );
  }
}
