import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ROLES } from 'src/app/models/role';
import { GoogleAnalyticsService } from 'src/app/services/common/google-analytics.service';
import { IdentityService } from 'src/app/services/common/identity.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { SelectSupplierHelperService } from 'src/app/services/helper/select-supplier-helper.service';
import { UserService } from 'src/app/services/users/user.service';
import { ILookupMaterials, IMaterialSpec } from '../../../models/lookup';
import { LookupService } from '../../../services/lookup/lookup.service';
import { ToastAlertComponent } from 'src/app/ui/toast-alert/toast-alert.component';
import { WebsocketService } from '../../../services/lib/websocket.service';
import { TimeoutConfig } from 'src/app/fixtures/app-settings/constants';

@Component({
  selector: 'app-lookup-page',
  templateUrl: './lookup-page.component.html',
})
export class LookupPageComponent implements OnInit {
  @ViewChild('search') searchElement!: ElementRef;

  mUser: any;
  supplierNumber: string = '';
  selectedProduct?: ILookupMaterials;
  materialIdSelected: string | null = null;
  userDateFormat: string = 'dd-MM-yyyy';
  categorySpecs: any;
  mainMaterialInfo = {
    productDisplayName: '',
    sapName: '',
    skuNumber: '',
    upcNumber: '',
  };

  // Subscription
  subscription: Subscription = new Subscription();

  // Loading & Warinigs
  noResultsWarning: boolean = false;
  loadingMaterial: boolean = false;
  loadingDownload: any = {
    loader: false,
  };
  toastErrorText: string = '';

  // Contact Us Form - MAIN FORM
  searchQueryControl = new FormControl();

  // Toast Components
  @ViewChild('noDocumentFoundToast') noDocumentFoundToast!: ToastAlertComponent;

  constructor(
    private lookupService: LookupService,
    private selectSupplierHelperService: SelectSupplierHelperService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private identityService: IdentityService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private helperService: HelperService,
    private websocketService: WebsocketService
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.categorySpecs = this.lookupService.getCategories();
    this.clearAndFocusSearchInput();
    this.getUserInfo();
    this.getIdRouteParameter();
    this.onMessageHandlerErrorHandler();
  }

  getMaterial() {
    let preSelectedMaterial: ILookupMaterials | undefined;
    this.subscription.add(
      this.lookupService.sharedMaterial.subscribe(
        (data) => (preSelectedMaterial = data)
      )
    );
    if (preSelectedMaterial != undefined) {
      this.selectedProduct = preSelectedMaterial;
      this.setMainMaterialInfo(this.selectedProduct);
    } else {
      if (this.mUser && this.materialIdSelected) {
        this.setSelectedProduct(this.materialIdSelected);
      }
    }
    this.lookupService.setSharedMaterial(undefined);
  }

  async getUserInfo() {
    this.identityService.getCurrentMeUser().subscribe({
      next: (user) => {
        if (user) {
          this.mUser = user;
          this.setDateFormat();
          this.checkUserRole(this.mUser);
          if (this.materialIdSelected) {
            this.setSelectedProduct(this.materialIdSelected);
          }
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  setDateFormat() {
    this.userDateFormat = this.mUser.dateFormat
      ? this.mUser.dateFormat
      : 'dd-MM-yyyy';
  }

  checkUserRole(user: any) {
    let userRole = user.role;
    if (
      userRole === ROLES.FMIC_ADMIN ||
      userRole === ROLES.FMIC_CUSTOMER_SERVICE
    ) {
      this.supplierNumber = this.checkForSupplierNumber();
    } else if (
      userRole === ROLES.SUPPIER_ADMIN ||
      userRole === ROLES.SUPPIER_FACTORY ||
      userRole === ROLES.SUPPIER_FINANCE ||
      userRole === ROLES.SUPPIER_MANAGER
    ) {
      this.supplierNumber = user.supplierNumber;
    }
  }

  checkForSupplierNumber(): string {
    let supplierNumber = '';
    this.selectSupplierHelperService.getValue().subscribe((value) => {
      if (value) {
        supplierNumber = value;
      }
    });
    return supplierNumber;
  }

  navigateToMaterial(material: string) {
    this.route.navigate([`lookup/${material}`]);
  }

  setSelectedProduct(material: string) {
    const composedMaterial = this.helperService.addLeadingZeros(material, 10);
    this.loadingMaterial = true;
    this.noResultsWarning = false;
    this.checkUserRole(this.mUser);
    this.subscription.add(
      this.lookupService
        .getMaterial(composedMaterial, this.supplierNumber)
        .subscribe({
          next: (data) => {
            if (data) {
              this.loadingMaterial = false;
              this.selectedProduct = data;
              this.setMainMaterialInfo(this.selectedProduct);
              this.googleAnalyticsService.trackEvent(
                'lookup_page',
                'Search Successful',
                material
              );
            }
          },
          error: () => {
            this.loadingMaterial = false;
            this.selectedProduct = undefined;
            this.noResultsWarning = true;
            this.googleAnalyticsService.trackEvent(
              'lookup_page',
              'Search Unsuccessful',
              material
            );
          },
          complete: () => {},
        })
    );
    this.clearAndFocusSearchInput();
  }
  clearAndFocusSearchInput() {
    this.searchQueryControl.setValue('');
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  setMainMaterialInfo(material: ILookupMaterials) {
    this.mainMaterialInfo = {
      productDisplayName: material.productDisplayName,
      sapName: material.productSpecs.sapName,
      skuNumber: material.selectedSku.skuNumber,
      upcNumber: material.productSpecs.upcNumber,
    };
  }

  downloadMaterialSpecs(material: string) {
    this.loadingDownload.loader = true;
    this.loadingDownload.timer = setTimeout(() => {
      this.loadingDownload.loader = false;
    }, TimeoutConfig.WEBSOCKET_LOADER);
    this.googleAnalyticsService.trackEvent(
      'lookup_page',
      'Click Download',
      material
    );
    this.subscription.add(
      this.lookupService.downloadMaterial(material).subscribe({
        next: (data) => {
          if (data && data.url) {
            this.helperService.downloadFromUrl(data.url);
            this.loadingDownload.loader = false;
            clearTimeout(this.loadingDownload.timer);
          }
        },
        error: (err) => {
          console.error(err);
        },
      })
    );
  }

  thumbnailClick(path: string) {
    this.googleAnalyticsService.trackEvent('lookup_page', 'Tile Click', path);
  }
  zoomClick(path: string) {
    this.googleAnalyticsService.trackEvent('lookup_page', 'View Zoom', path);
  }

  onMessageHandlerErrorHandler() {
    this.subscription.add(
      this.websocketService
        .returnWebsocketMessageHandler()
        .subscribe((response: any) => {
          if (this.loadingDownload.loader) {
            this.loadingDownload.loader = false;
            clearTimeout(this.loadingDownload.timer);
            this.websocketService.setHandlerMessage('');
          }
          if (response == 'No document found') {
            console.error(response);
            this.toastErrorText = response;
            this.noDocumentFoundToast?.show();
            this.websocketService.setHandlerMessage('');
          }
        })
    );
  }

  getIdRouteParameter() {
    this.activatedRoute.params.subscribe((routeParams) => {
      if (routeParams['materialId']) {
        this.materialIdSelected = routeParams['materialId'];
        this.getMaterial();
      }
    });
  }
}
