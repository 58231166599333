import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap, throwError, timeout } from 'rxjs';
import { IHelpQuestion } from 'src/app/models/help';
import { environment } from 'src/environments/environment';
import { IHelpCategory } from '../../models/help';
import { CacheService } from '../helper/cache.service';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService
  ) {}

  getCategories(token: string | null): Observable<IHelpCategory[]> {
    if (!token) {
      return throwError(() => new Error('Token Must Be Provided'));
    }
    const cacheCategories = this.cacheService.get('help-categories');
    if (cacheCategories) {
      return of(cacheCategories);
    }
    return this.httpClient
      .get<IHelpCategory[]>(`${environment.api.endPoint}/faq/categories`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
      })
      .pipe(
        tap((data: any) => {
          this.cacheService.set('help-categories', data);
        }),
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('FAQ API. Details: ' + err.error.message);
        })
      );
  }

  getHelpQuestions(
    token: string | null,
    category: string
  ): Observable<IHelpQuestion[]> {
    if (!token) {
      return throwError(() => new Error('Token Must Be Provided'));
    }
    const cacheQuestions = this.cacheService.get(`help-questions-${category}`);
    if (cacheQuestions) {
      return of(cacheQuestions);
    }
    return this.httpClient
      .get<IHelpQuestion[]>(`${environment.api.endPoint}/faq/${category}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
      })
      .pipe(
        tap((data: any) => {
          this.cacheService.set(`help-questions-${category}`, data);
        }),
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('FAQ API. Details: ' + err.error.message);
        })
      );
  }

  createQuestion(question: any, category: string, token: string | null) {
    if (!question || !token) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpClient
      .post(
        `${environment.api.endPoint}/faq/${category}`,
        this.prepareCreateQuestion(question, category),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'json',
        }
      )
      .pipe(
        tap(() => {
          this.cacheService.delete(`help-questions-${category}`);
        }),
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('FAQ API. Details: ' + err.error.message);
        })
      );
  }

  updateQuestion(
    question: any,
    category: string,
    id: string,
    token: string | null
  ) {
    if (!question || !token) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpClient
      .put(
        `${environment.api.endPoint}/faq/${category}/${id}`,
        this.prepareCreateQuestion(question, category),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'json',
        }
      )
      .pipe(
        tap(() => {
          this.cacheService.delete(`help-questions-${category}`);
        }),
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('FAQ API. Details: ' + err.error.message);
        })
      );
  }

  deleteQuestion(category: string, id: string, token: string | null) {
    if (!token) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpClient
      .delete(`${environment.api.endPoint}/faq/${category}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
      })
      .pipe(
        tap(() => {
          this.cacheService.delete(`help-questions-${category}`);
        }),
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('FAQ API. Details: ' + err.error.message);
        })
      );
  }

  getHowToVideos(token: string | null): Observable<IHelpQuestion[]> {
    if (!token) {
      return throwError(() => new Error('Token Must Be Provided'));
    }
    const cacheCategories = this.cacheService.get('how-to-videos');
    if (cacheCategories) {
      return of(cacheCategories);
    }
    return this.httpClient
      .get<IHelpQuestion[]>(`${environment.api.endPoint}/faq/how-to-videos`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
      })
      .pipe(
        tap((data: any) => {
          this.cacheService.set('how-to-videos', data, 15);
        }),
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('FAQ API. Details: ' + err.error.message);
        })
      );
  }

  createVideo(video: any, token: string | null) {
    if (!video || !token) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpClient
      .post(
        `${environment.api.endPoint}/faq/how-to-videos`,
        this.prepareCreateVideo(video),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'json',
        }
      )
      .pipe(
        tap(() => {
          this.cacheService.delete(`how-to-videos`);
        }),
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('FAQ API. Details: ' + err.error.message);
        })
      );
  }

  updateVideo(video: any, id: string, token: string | null) {
    if (!video || !token) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpClient
      .put(
        `${environment.api.endPoint}/faq/how-to-videos/${id}`,
        this.prepareCreateVideo(video),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'json',
        }
      )
      .pipe(
        tap(() => {
          this.cacheService.delete(`how-to-videos`);
        }),
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('FAQ API. Details: ' + err.error.message);
        })
      );
  }

  deleteVideo(id: string, token: string | null) {
    if (!token) {
      return throwError(() => new Error('Parameters Must Be Provided'));
    }
    return this.httpClient
      .delete(`${environment.api.endPoint}/faq/how-to-videos/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
      })
      .pipe(
        tap(() => {
          this.cacheService.delete(`how-to-videos`);
        }),
        timeout(environment.api.timeout),
        catchError((err) => {
          throw new Error('FAQ API. Details: ' + err.error.message);
        })
      );
  }

  getVideoFromUrl(selectedQuestion: IHelpQuestion) {
    const { url, title, ...rest } = selectedQuestion || {};

    return url
      ? {
          url,
          title,
          ...rest,
        }
      : null;
  }

  // Private Functions
  private prepareCreateQuestion(question: any, category: string) {
    if (!question || !category) {
      return null;
    }
    const helpQuestionObject = {
      title: question.question,
      description: question.answer,
      category: category,
      url: question.videoUrl ? question.videoUrl : undefined,
    };
    return helpQuestionObject;
  }

  private prepareCreateVideo(video: any) {
    if (!video) {
      return null;
    }
    const helpVideoObject = {
      title: video.title,
      category: 'how-to-videos',
      url: video.videoUrl,
    };
    return helpVideoObject;
  }
}
