import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { IHelpVideo } from 'src/app/models/videos';
import { HelpService } from 'src/app/services/help/help.service';
import { Input } from '@angular/core';
import { ToastAlertComponent } from 'src/app/ui/toast-alert/toast-alert.component';
import { CatalogService } from '../../../services/users/catalog.service';
import { CacheService } from '../../../services/helper/cache.service';
import { DragulaService } from 'ng2-dragula';
import { TimeoutConfig } from 'src/app/fixtures/app-settings/constants';

@Component({
  selector: 'app-help-videos',
  templateUrl: './help-videos.component.html',
})
export class HelpVideosComponent implements OnInit {
  @Input() token: any;
  @Input() canEdit: boolean = false;

  public selectedVideo: IHelpVideo | undefined;
  selectedVideoId: string = '';
  addEditVideo: FormGroup;
  public dialogVideo: any;
  public loading: Boolean = false;
  public autoplay: Boolean = false;
  public error: Boolean = false;
  subscription: Subscription = new Subscription();

  // Alerts
  notificationDialogMessage: string = '';
  showDiscardAlert: boolean = false;
  showDeleteAlert: boolean = false;
  buttonLoading: boolean = false;
  showCreateAlert: boolean = false;
  genericError: boolean = false;
  successAlert: boolean = false;

  // Toast Components
  @ViewChild('deleteToast') deleteToastAlert!: ToastAlertComponent;

  // Loadings
  videosLoading: boolean = false;

  modalOnEdit: boolean = false;

  selectedVideoTitle: string = '';
  selectedVideoUrl: string = '';

  @Output() selectVideo = new EventEmitter();

  @ViewChild('video', { static: false }) videoModal?: ModalDirective;
  @ViewChild('videoModal', { static: false }) formModal?: ModalDirective;

  helpVideos: IHelpVideo[] = [];

  constructor(
    private helpService: HelpService,
    private formBuilder: FormBuilder,
    private catalogService: CatalogService,
    private cacheService: CacheService,
    private _dragulaService: DragulaService
  ) {
    this.addEditVideo = formBuilder.group({
      title: new FormControl('', [Validators.required]),
      videoUrl: new FormControl('', [Validators.required]),
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this._dragulaService.destroy('Videos');
  }

  ngOnInit(): void {
    this.getHowToVideos();
    this.dragulaInit();
  }

  getHowToVideos() {
    this.videosLoading = true;
    this.subscription.add(
      this.helpService.getHowToVideos(this.token).subscribe({
        next: (response: any) => {
          this.helpVideos = response;
        },
        complete: () => {
          this.videosLoading = false;
        },
        error: (error: any) => {
          console.error(error);
          this.videosLoading = false;
        },
      })
    );
  }

  onSelectVideo(videoSelected: any) {
    this.selectVideo.emit(videoSelected);
  }

  onDiscardVideo() {
    if (this.addEditVideo.pristine) {
      this.addEditVideo.reset();
      this.formModal?.hide();
    } else {
      this.notificationDialogMessage = 'Do you want to close without save?';
      this.showDiscardAlert = true;
    }
  }

  confirmDiscardVideo(confirmation: boolean) {
    this.showDiscardAlert = false;
    if (confirmation) {
      this.formModal?.hide();
      this.addEditVideo.reset();
    }
  }

  onDeleteVideo() {
    this.notificationDialogMessage = 'Do you want to delete this video?';
    this.showDeleteAlert = true;
  }

  confirmDeleteVideo(confirmation: boolean) {
    this.showDeleteAlert = false;
    if (confirmation) {
      this.subscription.add(
        this.helpService
          .deleteVideo(this.selectedVideoId, this.token)
          .subscribe({
            complete: () => {
              this.deleteToastAlert?.show();
              this.selectedVideoId = '';
              this.addEditVideo.reset();
              this.getHowToVideos();
              this.formModal?.hide();
            },
          })
      );
    }
  }

  onEditVideo(video: any) {
    this.selectedVideoId = video.id;
    this.modalOnEdit = true;
    this.addEditVideo.patchValue(
      {
        title: video.title,
        videoUrl: video.url,
      },
      { emitEvent: false }
    );
    this.addEditVideo.markAsPristine();
    this.formModal?.show();
  }

  saveUpdatedVideo() {
    this.buttonLoading = true;
    this.showCreateAlert = false;
    this.genericError = false;
    this.subscription.add(
      this.helpService
        .updateVideo(this.addEditVideo.value, this.selectedVideoId, this.token)
        .subscribe({
          next: (updated) => {
            if (updated) {
              this.successAlert = true;
            }
          },
          complete: () => {
            this.buttonLoading = false;
            this.selectedVideoId = '';
            this.addEditVideo.reset();
            this.getHowToVideos();
            setTimeout(() => {
              this.successAlert = false;
              this.formModal?.hide();
            }, TimeoutConfig.ALERT_BANNER_SUCCESS);
          },
          error: (err) => {
            this.buttonLoading = false;
            this.genericError = true;
            setTimeout(() => {
              this.genericError = false;
            }, TimeoutConfig.ALERT_BANNER_ERROR);
            console.error(err);
          },
        })
    );
  }

  onNewVideo() {
    this.modalOnEdit = false;
    this.addEditVideo.reset();
    this.formModal?.show();
  }

  saveNewVideo() {
    this.buttonLoading = true;
    this.showCreateAlert = false;
    this.genericError = false;
    this.subscription.add(
      this.helpService
        .createVideo(this.addEditVideo.value, this.token)
        .subscribe({
          next: (saved) => {
            if (saved) {
              this.successAlert = true;
            }
          },
          complete: () => {
            this.buttonLoading = false;
            this.addEditVideo.reset();
            this.getHowToVideos();
            setTimeout(() => {
              this.successAlert = false;
              this.formModal?.hide();
            }, TimeoutConfig.ALERT_BANNER_SUCCESS);
          },
          error: (err) => {
            this.buttonLoading = false;
            this.genericError = true;
            setTimeout(() => {
              this.genericError = false;
            }, TimeoutConfig.ALERT_BANNER_ERROR);
            console.error(err);
          },
        })
    );
  }

  // Drag & Drop

  dragulaInit() {
    this._dragulaService.createGroup('Videos', {
      moves: (el, container, handle: any) => {
        return this.canEdit ? true : false;
      },
    });
  }

  dragDropEvent(event: any) {
    const videosArray = this.helpVideos.map((video) => video.id);
    this.subscription.add(
      this.catalogService.sortList(`faq-how-to-videos`, videosArray).subscribe({
        complete: () => {
          this.cacheService.delete(`how-to-videos`);
        },
        error: (error) => {
          console.error(error);
        },
      })
    );
  }
}
