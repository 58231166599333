import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  OnDestroy,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { input } from 'aws-amplify';

const DEFAULT_HEIGHT = '120px';
const DEFAULT_WIDTH = '240px';

@Component({
  selector: 'static-video-thumbnail',
  templateUrl: 'static-video-thumbnail.component.html',
})
export class StaticVideoThumbnailComponent
  implements OnInit, OnChanges, OnDestroy {
  @Output() onClickVideo: EventEmitter<any> = new EventEmitter();
  @Output() activityChange = new EventEmitter();
  @Input() height = DEFAULT_HEIGHT;
  @Input() width = DEFAULT_WIDTH;
  @Input() videoThumbnail!: string;
  @Input() videoId!: string;
  @Input() videoUrl!: string;
  @Input() videoTitle!: string;
  @Input() eventId!: string;
  @Input() trackActivity!: boolean;
  @Input() progressPercentage: number = 0;
  @Input() videoSubtitles!: any[];
  @Input() eventCategory!: string;
  @Input() isAdditionalTile!: boolean;
  @Input() useCircleProgressbar = false;
  @Input() eventType!: string;
  @Input() videoType!: string;
  @Input() pageSelected!: string | null;
  @Input() pageTitle!: string;
  @Input() startTime: number = 0;

  public videoActivity: any;
  public hover = false;
  public loading!: Boolean;

  @ViewChild('videoContainer', { static: true })
  videoCont!: ElementRef;

  private _subs = new Subscription();

  @ViewChild('videoPlayerModal', { static: true })
  videoPlayerModal!: TemplateRef<ElementRef>;
  public modalRef!: BsModalRef;

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    if (!this.videoThumbnail) {
      this.videoThumbnail = 'assets/images/fender-white.svg';
    }
  }

  ngOnChanges(changes: any) {
    this.loading = true;

    if (changes.width) {
      this.width = this.width || DEFAULT_WIDTH;
    }

    if (changes.height) {
      this.height = this.height || DEFAULT_HEIGHT;
    }

    if (changes.videoThumbnail) {
      this.loading = false;
    }
  }

  showVideo() {
    this.onClickVideo.emit();
    this.modalRef = this.modalService.show(this.videoPlayerModal, {
      keyboard: false,
      class: 'modal-lg bs-modal-transparent',
    });
  }

  onActivityChange(activity: any) {
    this.activityChange.emit(activity);
    if (activity && activity?.progressPercentage) {
      if (activity.progressPercentage > this.progressPercentage){
        this.progressPercentage = activity.progressPercentage;
        this.startTime = activity?.progress;
      }
    }
  }

  onLanguageSelected(language: any) {
    // this.subtitleSelected.emit(language);
  }

  onStatusChanged(status: any) {
    // this.statusChanged.emit(status);
  }

  closeVideo() {
    this.modalRef.hide();
  }

  onActivityChanged(videoActivity: any) {
    this.videoActivity = videoActivity;
  }

  onSubtitleSelected(language: any) {
    // Subtitle changed
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
