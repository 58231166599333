import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { UiModule } from '../ui/ui.module';
import { MyDocumentsComponent } from './documents/my-documents/my-documents.component';
import { DocumentsPageComponent } from './documents/documents-page/documents-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyAnnouncementsComponent } from './announcements/my-announcements/my-announcements.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HelpPageComponent } from './help/help-page/help-page.component';
import { HelpQuestionsComponent } from './help/help-questions/help-questions.component';
import { HelpVideosComponent } from './help/help-videos/help-videos.component';
import { HtmlLinebreakPipe } from '../pipes/html-linebreak.pipe';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PurchaseOrdersModule } from './purchase-orders/purchase-orders.module';
import { LookupPageComponent } from './lookup/lookup-page/lookup-page.component';
import { MyLookupComponent } from './lookup/my-lookup/my-lookup.component';
import { ApplicationPipesModule } from '../pipes/application-pipes.module';
import { DragulaModule } from 'ng2-dragula';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [
    HomeComponent,
    MyDocumentsComponent,
    DocumentsPageComponent,
    MyAnnouncementsComponent,
    HelpPageComponent,
    HelpQuestionsComponent,
    HelpVideosComponent,
    HtmlLinebreakPipe,
    LookupPageComponent,
    MyLookupComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    UiModule,
    ApplicationPipesModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    PurchaseOrdersModule,
    DragulaModule,
    AngularEditorModule,
  ],
  exports: [HtmlLinebreakPipe],
})
export class DashboardModule {}
