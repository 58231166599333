import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, first, take } from 'rxjs';
import { PoHelperService } from '../../../services/purchase-orders/po-helper.service';
import {
  IPoDateRangeFormGroupFilter,
  dateRangeFormGroupNames,
} from 'src/app/fixtures/purchase-orders/date-range-filter';

@Component({
  selector: 'app-po-dropdown-date-filter',
  templateUrl: './po-dropdown-date-filter.component.html',
})
export class PoDropdownDateFilterComponent implements OnInit {
  @Output() onDatePickerChange = new EventEmitter();
  // Form Group
  dateRangeFilterForm = new FormGroup({
    poCreateDate: new FormGroup({
      from: new FormControl(''),
      to: new FormControl(''),
    }),
    productionMonth: new FormGroup({
      from: new FormControl(''),
      to: new FormControl(''),
    }),
    originalShipDate: new FormGroup({
      from: new FormControl(''),
      to: new FormControl(''),
    }),
    currentShipDate: new FormGroup({
      from: new FormControl(''),
      to: new FormControl(''),
    }),
  });

  constructor(private poHelperService: PoHelperService) {}

  getGroup(group: string): FormGroup {
    return this.dateRangeFilterForm?.get(group) as FormGroup;
  }

  ngOnInit(): void {
    this.onFormChanegs();
  }

  onFormChanegs() {
    this.dateRangeFilterForm.valueChanges
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((value) => {
        const formIsEmpty = this.poHelperService.isDateRangeFilterFormEmpty(
          this.dateRangeFilterForm
        );
        if (!formIsEmpty) {
          this.onDatePickerChange.emit(value);
        }
      });
  }

  clear() {
    this.dateRangeFilterForm.reset();
  }
}
