import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Popover } from 'bootstrap';
import { AuthenticationService } from 'src/app/services/users/authentication.service';
import { UserService } from '../../services/users/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  passwordUpdatedMessage: boolean = false;
  isError: boolean = false;
  isCodeError: boolean = false;
  errorCodeMessage: string = '';
  passwordPoliciesText: string =
    'Passwords must be at least 8 characters in length and contain characters from at least three of the following categories: Uppercase letters (A-Z), Lowercase letters (a-z), Numbers (base 10 digits 0 €" 9) or Non-alphabetic characters such as (` ~ ! @ # $ % ^ & * - + = | { } [ ] : ; < > , . ? /).';
  safePasswordError: boolean = false;
  loading: boolean = false;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService
  ) {
    this.resetPasswordForm = this.formBuilder.group(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        verificationCode: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/),
        ]),
        confirmPassword: new FormControl('', [Validators.required]),
      },
      { validators: this.passwordsMatchValidator }
    );
  }

  ngOnInit(): void {
    this.createPopOver();
    this.setEmailField();
  }

  setEmailField() {
    this.route.queryParams.subscribe((params) => {
      if (params['username']) {
        this.resetPasswordForm.get('email')?.setValue(params['username']);
        document.getElementById('verificationCodeInput')?.focus();
      } else {
        document.getElementById('emailInput')?.focus();
      }
    });
  }

  passwordsMatchValidator(resetPasswordForm: FormGroup) {
    return resetPasswordForm.controls['newPassword'].value ===
      resetPasswordForm.controls['confirmPassword'].value
      ? null
      : { passwordsMismatch: true };
  }

  validateSafePassword() {
    this.loading = true;
    const newPassword = this.resetPasswordForm.get('newPassword')?.value;
    this.safePasswordError = false;
    this.userService.validateUserSafePassword(newPassword).subscribe({
      next: () => {
        this.updatePassword();
      },
      error: (err) => {
        console.error(err);
        this.listenToNewPasswordChange();
        this.safePasswordError = true;
        this.loading = false;
      },
    });
  }

  listenToNewPasswordChange() {
    this.resetPasswordForm.controls['newPassword'].valueChanges.subscribe(
      () => {
        this.safePasswordError = false;
      }
    );
  }

  updatePassword() {
    this.loading = true;
    this.isError = false;
    this.isCodeError = false;
    this.errorCodeMessage = '';
    const username = this.resetPasswordForm.get('email')?.value.toLowerCase();
    const code = this.resetPasswordForm.get('verificationCode')?.value;
    const newPassword = this.resetPasswordForm.controls['newPassword'].value;
    this.authenticationService
      .submitForgotPassword(username, code, newPassword)
      .then(
        (response) => {
          if (response === 'SUCCESS') {
            this.passwordUpdatedMessage = true;
            setTimeout(() => {
              this.router.navigateByUrl('/login');
            }, 3000);
          } else {
            this.isError = true;
          }
          this.loading = false;
        },
        (error) => {
          // Code Expired
          if (
            error.message.includes(
              'Invalid code provided, please request a code again.'
            )
          ) {
            this.errorCodeMessage =
              'Your verification code has expired. Please contact your admin user to refresh your account.';
            this.isCodeError = true;
            // Code Mismatch
          } else if (
            error.message.includes(
              'Invalid verification code provided, please try again.'
            )
          ) {
            this.errorCodeMessage =
              'Invalid verification code provided, please try again.';
            this.isCodeError = true;
          } else {
            this.isError = true;
          }
        }
      );
    this.loading = false;
  }

  private createPopOver() {
    var list = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    list.map(function (el: Element) {
      return Popover.getOrCreateInstance(el);
    });
  }
}
