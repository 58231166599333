// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { CookieStorage } from 'amazon-cognito-identity-js';

export const environment = {
  production: false,
  environment: 'qa',
  cognito: {
    identityPoolId: 'us-west-2:8033f803-037a-4372-b8a5-73d847aeeaa2',
    userPoolWebClientId: '245dt8flfa6e997bfdh6ef5f11',
    userPoolId: 'us-west-2_amtnAHQvf',
    region: 'us-west-2',
    authenticationFlowType: 'USER_SRP_AUTH',
    cookieStorage: new CookieStorage({
      domain: 'qa.supplier.fender.com',
      secure: true,
      path: '/',
      expires: 1,
      sameSite: 'strict',
    }),
  },
  api: {
    endPoint: 'https://qa.api.supplier.fender.com',
    timeout: 180000,
    users: {
      limit: 60,
    },
  },
  storage: {
    AWSS3: {
      bucket: 'qa.storage.supplier.portal.com',
      region: 'us-west-2',
    },
  },
  websocket: {
    url: 'wss://qa.alerts.supplier.fender.com',
  },
  google: { analytics: 'G-9GCPXSHHP2' },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
